export const INVALID_POSTAL_CODE = 'Invalid Postal Code';
export const INVALID_CITY = 'Invalid City';
export const SHOULD_INCLUDE_NUMBERS_OR_LETTERS =
    'Should include numbers or letters';
export const ONLY_POSITIVE_NUMBERS = 'Only positive numbers';
export const ENTER_QUANTITY = 'Enter Quantity';
export const INVALID_EMAIL = 'Invalid Email';
export const INVALID_AUTH_EMAIL = 'Email format should be yourname@example.com';
export const INVALID_PHONE_NUMBER = 'Invalid Phone Number';
export const INVALID_TRACKING_NUMBER = 'Invalid Tracking Number';

export const NO_MATCHING_ITEMS =
    'No matching items found. Try changing the search criteria';

interface ErrorPrefix {
    [key: string]: string;
}

export const errorPrefix: ErrorPrefix = {
    postalCode: 'Postal Code',
    city: 'City',
    deliveryCity: 'City',
    email: 'Email',
    companyName: 'Company Name',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    contactPerson: 'Contact Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    clientSerial: 'Serial Number',
    profileName: 'Profile Name'
};
