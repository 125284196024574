import React from 'react';

import { Badge as AntBadge, BadgeProps } from 'antd';

import styles from './Badge.module.less';

interface BadgeType extends BadgeProps {
    node: BadgeProps['children'];
}

export const Badge = ({ node, count, ...props }: BadgeType) => {
    const shouldDisplayDot = !count;

    return (
        <AntBadge
            className={styles.Container}
            dot={shouldDisplayDot}
            count={count}
            data-testid="badge"
            {...props}
        >
            {node}
        </AntBadge>
    );
};
