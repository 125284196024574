import React, { RefObject, useRef } from 'react';

import { InputRef } from 'antd';
import classNames from 'classnames';

import { TextInput } from '@atoms/input/Input';
import { Refresh } from '@atoms/refresh/Refresh';
import { FilterItem } from '@customTypes/mirAndTracking/MirAndTracking.types';
import { Filter } from '@molecules/filters/filter/Filter';

interface Props {
    title: string;
    searchPlaceholder: string;
    searchQuery?: string;
    searchInputValue?: string;
    onChangeSearch: (value: string) => void;
    onSearchInputClean: VoidFunction;
    onSubmitSearch?: (value: string) => void;
    styles: {
        [key: string]: string;
    };
    onRefreshClick: VoidFunction;
    lastUpdated?: string;
    isMobile?: boolean;
    filterSettings: FilterItem[];
    children: React.ReactNode;
    isTracking: boolean;
    onlyOneFiltersType?: boolean;
    isAllFiltersPanelOpen?: boolean;
    toggleAllFiltersPanel?: VoidFunction;
}

export const SearchAndFilters = ({
    styles,
    title,
    onSubmitSearch,
    onSearchInputClean,
    searchQuery,
    searchInputValue,
    onChangeSearch,
    searchPlaceholder,
    onRefreshClick,
    isMobile,
    lastUpdated,
    filterSettings,
    children,
    isTracking,
    onlyOneFiltersType = true,
    isAllFiltersPanelOpen,
    toggleAllFiltersPanel
}: Props) => {
    const searchInputRef: RefObject<InputRef> = useRef(null);

    const handleFocus = () => {
        if (searchInputRef.current) {
            setTimeout(() => {
                searchInputRef?.current?.select();
            }, 100);
        }
    };

    return (
        <div className={styles.Container}>
            <div className={styles.Header__Wrapper}>
                <div className={styles.Header__Title}>{title}</div>
                <Refresh
                    onRefreshClick={onRefreshClick}
                    lastUpdated={lastUpdated}
                    isMobile={isMobile}
                />
            </div>
            <div
                className={classNames(styles.FiltersBar, {
                    [styles.FiltersBar__MaxWidth]: !isTracking
                })}
            >
                <div
                    className={classNames(styles.Search, {
                        [styles.Search__MaxWidth]: !isTracking
                    })}
                    data-testid="SearchInputBlock"
                >
                    <TextInput
                        data-testid="SearchInput"
                        isSearch={true}
                        innerRef={searchInputRef}
                        placeholder={searchPlaceholder}
                        value={searchInputValue}
                        onChange={onChangeSearch}
                        withCross={Boolean(searchInputValue)}
                        onFocus={handleFocus}
                        onClick={
                            isAllFiltersPanelOpen && toggleAllFiltersPanel
                                ? toggleAllFiltersPanel
                                : undefined
                        }
                        onClear={onSearchInputClean}
                        onPressEnter={({ target }) =>
                            onSubmitSearch && onSubmitSearch(target.value)
                        }
                    />
                </div>
                <div className={styles.Filters} data-testid="FiltersBlock">
                    {filterSettings.map((item, index) => {
                        const key = `${item.filterName}_${index}`;
                        if (item.isVisible) {
                            return item.filterComponent ? (
                                <div key={key}>{item.filterComponent}</div>
                            ) : (
                                <Filter
                                    key={key}
                                    icon={item.icon ? item.icon : undefined}
                                    filterName={item.filterName || ''}
                                    dataTestId={item.filterName}
                                    onClick={item.onClick}
                                    isSelected={
                                        item.isSelected
                                            ? item.isSelected
                                            : undefined
                                    }
                                    customId={
                                        item.customId ? item.customId : ''
                                    }
                                    withArrow={
                                        item.withArrow ? item.withArrow : false
                                    }
                                    disabled={
                                        onlyOneFiltersType &&
                                        Boolean(searchQuery)
                                    }
                                />
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
            {children}
        </div>
    );
};
