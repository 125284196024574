import { RequestStatusWithData } from '@customTypes/api/api.types';

export interface GetLogOnTokenData {
    logOnToken: string;
}

export interface Dashboard {
    id: string;
    keys: string[];
}

export interface PermissionDashboard {
    permissionKeys: string[];
    dashboards: Dashboard[];
}

export interface GetDashboardsData {
    permissionDashboards: PermissionDashboard[];
}

export type GetLogOnTokenResponse = RequestStatusWithData<GetLogOnTokenData>;
export type GetDashboardsResponse = RequestStatusWithData<GetDashboardsData>;

export enum REQUESTS_PATH {
    GET_LOGON_TOKEN = '/Dundas/GetLogonToken',
    GET_DASHBOARDS = '/Dundas/GetDashboards'
}
