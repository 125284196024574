import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { AxiosError, AxiosProgressEvent } from 'axios';

import AuthApi from '@apis/auth/auth.api';
import { B2CController } from '@configs/B2CController';
import {
    LOCAL_STORAGE_FIELDS,
    setLocalStorageItem
} from '@helpers/localStorage';
import {
    getNotification,
    NOTIFICATION_TYPES
} from '@notifications/Notification';

export type QueryWithIntercept = BaseQueryFn<
    FetchArgs,
    unknown,
    unknown,
    {
        onUploadProgress?: (data: AxiosProgressEvent) => void;
        flowStart?: boolean;
        flowEnd?: boolean;
    }
>;

const mutex = new Mutex();

const SIGN_OUT_DELAY = 500;
const signOutWithDelay = () => {
    setTimeout(() => {
        AuthApi.signOut();
    }, SIGN_OUT_DELAY);
};

let isRefreshingToken = false;
let refreshTokenPromise: Promise<void> | null = null;
let pendingRequests: Array<() => Promise<unknown>> = [];

export const queryWithIntercept =
    (
        baseQuery: (token: string | null) => QueryWithIntercept
    ): QueryWithIntercept =>
    async (args, api, extraOptions) => {
        const acToken = AuthApi.getAccessToken();
        const b2cConfig = B2CController.getInstance().getConfig();

        const processPendingRequests = () => {
            pendingRequests.forEach((callback) => callback());
            pendingRequests = [];
        };

        let result = await baseQuery(acToken)(args, api, extraOptions);
        const resultMeta = result.meta as { response: Response };
        const resultHeaders = resultMeta?.response?.headers;
        const resultError = result.error as AxiosError | FetchBaseQueryError;

        const unauthorizedCode = resultHeaders?.get('Unauthorized-Reason-Code');
        const unauthorizedReason = resultHeaders?.get('Unauthorized-Reason');
        const flowId = resultHeaders?.get('x-correlation-id-flow');

        if (extraOptions?.flowStart && flowId) {
            api.dispatch({
                type: 'user/setCurrentFlowId',
                payload: flowId
            });
        }

        if (extraOptions?.flowEnd) {
            api.dispatch({
                type: 'user/setCurrentFlowId',
                payload: ''
            });
        }

        if (unauthorizedCode && unauthorizedReason) {
            getNotification({
                title: unauthorizedReason,
                type: NOTIFICATION_TYPES.ERROR
            });
        }

        if (result.error && Number(resultError.status) === 401) {
            const refreshToken = AuthApi.getRefreshToken();

            if (!refreshToken) {
                signOutWithDelay();
                return result;
            }

            if (isRefreshingToken) {
                return new Promise((resolve, reject) => {
                    pendingRequests.push(async () => {
                        try {
                            const retryResult = await baseQuery(
                                AuthApi.getAccessToken()
                            )(args, api, extraOptions);
                            resolve(retryResult);
                        } catch (error) {
                            reject(error);
                        }
                    });
                });
            } else {
                const release = await mutex.acquire();
                isRefreshingToken = true;

                refreshTokenPromise = new Promise<void>(
                    async (resolve, reject) => {
                        try {
                            const newToken = await AuthApi.refreshToken({
                                refresh_token: refreshToken,
                                response_type: 'token id_token',
                                client_id: b2cConfig.clientId,
                                grant_type: 'refresh_token'
                            });

                            if (newToken) {
                                setLocalStorageItem(
                                    LOCAL_STORAGE_FIELDS.ACCESS_TOKEN,
                                    newToken.data.access_token
                                );
                                processPendingRequests();
                                resolve();
                            } else {
                                signOutWithDelay();
                                reject(new Error('Failed to refresh token'));
                            }
                        } catch (err) {
                            signOutWithDelay();
                            reject(err);
                        } finally {
                            isRefreshingToken = false;
                            refreshTokenPromise = null;
                            release();
                        }
                    }
                );

                await refreshTokenPromise;
                result = await baseQuery(AuthApi.getAccessToken())(
                    args,
                    api,
                    extraOptions
                );
            }
        }

        return result;
    };
