import { AccountSubAccount } from '@customTypes/api/api.types';

export enum REQUESTS_PATH {
    PARTS = '/ReturnParts/GoodToChoice/Parts',
    ACCOUNT_WAREHOUSE = '/ReturnParts/GoodToChoice/AccountSSLs',
    GET_SERVICES = '/ReturnParts/GoodToChoice/AvailableServices',
    REFERENCE_LABELS = '/ReturnParts/GoodToChoice/GetOrderReferenceNumberLabels',
    SUBMIT = '/ReturnParts/GoodToChoice/Submit'
}

export interface GetAvailableReturnWarehouses extends AccountSubAccount {
    destinationCountry?: string;
    destinationPostalCode?: string;
    destinationCity?: string;
    destinationProvince?: string;
}
