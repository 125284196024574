import React from 'react';

import { pageHeadersList } from '@constants/pageTitles';
import { usePageTitle } from '@helpers/usePageTitle';
import { OrdersListTemplate } from '@templates/trackOrders/ordersList/OrdersList.template';

export const OrdersListPage = () => {
    usePageTitle(pageHeadersList.TRACK_ORDERS.ROOT);

    return <OrdersListTemplate />;
};
