import { profilesApi } from '@apis/profiles/Profiles.api';

export const getProfilesApiByRole = (isChoiceAdmin: boolean) => {
    const {
        useSaveProfileMutation,
        useGetProfileMutation,
        useLazyGetProfileByIdQuery,
        useGetProfilesClientsQuery,
        useDeleteProfileMutation,
        useEditProfileMutation
    } = profilesApi;

    if (isChoiceAdmin) {
        return {
            useSaveProfileMutation,
            useGetProfileMutation,
            useLazyGetProfileByIdQuery,
            useGetProfilesClientsQuery,
            useEditProfileMutation,
            useDeleteProfileMutation
        };
    }

    return {
        useSaveProfileMutation,
        useGetProfileMutation,
        useLazyGetProfileByIdQuery,
        useGetProfilesClientsQuery,
        useEditProfileMutation,
        useDeleteProfileMutation
    };
};
