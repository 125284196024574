import * as React from 'react';
import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
    color?: string;
}

export const SvgPaperClip = (props: Props) => (
    <svg
        width={16}
        height={17}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_21871_406589)">
            <path
                d="M14.2948 7.86678L8.16816 13.9934C7.4176 14.744 6.39962 15.1657 5.33816 15.1657C4.27671 15.1657 3.25872 14.744 2.50816 13.9934C1.7576 13.2429 1.33594 12.2249 1.33594 11.1634C1.33594 10.102 1.7576 9.084 2.50816 8.33344L8.63483 2.20678C9.1352 1.7064 9.81386 1.42529 10.5215 1.42529C11.2291 1.42529 11.9078 1.7064 12.4082 2.20678C12.9085 2.70715 13.1896 3.3858 13.1896 4.09344C13.1896 4.80108 12.9085 5.47973 12.4082 5.98011L6.27483 12.1068C6.02464 12.357 5.68531 12.4975 5.3315 12.4975C4.97768 12.4975 4.63835 12.357 4.38816 12.1068C4.13797 11.8566 3.99742 11.5173 3.99742 11.1634C3.99742 10.8096 4.13797 10.4703 4.38816 10.2201L10.0482 4.56678"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_21871_406589">
                <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
