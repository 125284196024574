import { useLazyGetAccountsAndSubAccountsQuery } from 'APIServices/users/Users.api';
import {
    Account,
    AccountsSubAccounts,
    SubAccount
} from 'APIServices/users/UsersApi.types';
import { useAppSelector } from 'store';
import { UserTokenInfo } from 'store/slices/user/user';
import { ACTIVE_ORDER_TYPE } from 'utils/types/order/order.types';

export interface UserData
    extends Pick<
            SubAccount,
            | 'isExpandedRef'
            | 'isMandatoryRef2'
            | 'irpItemRef'
            | 'itemRef'
            | 'subAccountNumber'
            | 'description'
        >,
        Pick<Account, 'accountNumber'> {
    fetchAccounts: (onlyTransactional: boolean) => Promise<AccountsSubAccounts>;
    includeSubs?: boolean;
    accounts?: Account[];
    accountsLoading: boolean;
    userInfo: UserTokenInfo | null;
    getChosenReferenceType?: (orderType?: string) => boolean | undefined;
    selectedAccountIds: string;
}

export const useUser = (): Partial<UserData> => {
    const userInfo = useAppSelector(({ user: { userInfo } }) => userInfo);
    const [getAccounts, { data: accounts, isLoading: accountsLoading }] =
        useLazyGetAccountsAndSubAccountsQuery();

    const {
        INBOUND_REPLENISHMENT_BY_PART,
        INBOUND_REPLENISHMENT_BY_PART_DEFECTIVE,
        INBOUND_REPLENISHMENT_BY_UPLOAD,
        INBOUND_REPLENISHMENT_BY_UPLOAD_DEFECTIVE
    } = ACTIVE_ORDER_TYPE;

    const fetchAccounts = async (onlyTransactional = false) => {
        return getAccounts({ onlyTransactional: onlyTransactional }).unwrap();
    };

    const {
        accountNumber,
        description,
        subAccountNumber,
        isExpandedRef,
        isMandatoryRef2,
        itemRef,
        irpItemRef,
        selectedAccountIds
    } = useAppSelector(
        ({
            adminPanel: {
                selectedAccountAndSubAccount: {
                    accountNumber,
                    subAccountNumber,
                    description,
                    isExpandedRef,
                    isMandatoryRef2,
                    itemRef,
                    irpItemRef,
                    selectedAccountIds
                }
            }
        }) => ({
            accountNumber,
            description,
            subAccountNumber,
            isExpandedRef,
            isMandatoryRef2,
            itemRef,
            irpItemRef,
            selectedAccountIds
        })
    );

    const getChosenReferenceType = (orderType?: string) => {
        return orderType === INBOUND_REPLENISHMENT_BY_PART ||
            orderType === INBOUND_REPLENISHMENT_BY_PART_DEFECTIVE ||
            orderType === INBOUND_REPLENISHMENT_BY_UPLOAD ||
            orderType === INBOUND_REPLENISHMENT_BY_UPLOAD_DEFECTIVE
            ? irpItemRef
            : itemRef;
    };

    return {
        accounts: accounts?.data,
        accountsLoading,
        selectedAccountIds,
        accountNumber: accountNumber,
        description,
        subAccountNumber: subAccountNumber,
        isExpandedRef: isExpandedRef,
        isMandatoryRef2: isMandatoryRef2,
        itemRef: itemRef,
        irpItemRef: irpItemRef,
        includeSubs: true,
        userInfo,
        getChosenReferenceType,
        fetchAccounts
    };
};
