import React, { ReactNode } from 'react';

import { TooltipPlacement } from 'antd/lib/tooltip';
import classNames from 'classnames';

import { ToolTip } from '@atoms/tooltip/ToolTip';

import styles from './Label.module.less';

export enum LabelVariants {
    GREEN = 'Green',
    VIOLET = 'Violet',
    YELLOW = 'Yellow',
    BLUE = 'Blue',
    RED = 'Red',
    NEUTRAL = 'Neutral',
    PINK = 'Pink'
}

export interface LabelProps {
    name: string;
    variant: LabelVariants;
    tooltipText?: ReactNode;
    placement?: TooltipPlacement;
    className?: string;
    isMobileReducedWidth?: boolean;
}

export const Label = ({
    name,
    variant,
    tooltipText,
    placement = 'bottom',
    className,
    isMobileReducedWidth = false
}: LabelProps) => {
    return tooltipText ? (
        <ToolTip
            title={tooltipText}
            placement={placement}
            mobileReducedWidth={isMobileReducedWidth}
            regularDisplayMode={true}
        >
            <div
                className={classNames(
                    styles.Label,
                    styles[`Label__${variant}`],
                    styles.Label__Tooltip,
                    className
                )}
                id="TooltipLabelName"
                data-testid="tooltip-name"
            >
                {name}
            </div>
        </ToolTip>
    ) : (
        <div
            className={classNames(
                styles.Label,
                styles[`Label__${variant}`],
                className
            )}
            id="LabelName"
            data-testid="label-name"
        >
            {name}
        </div>
    );
};
