import React, { useEffect, useState, startTransition } from 'react';

import { TreeSelect as AntTreeSelect, TreeSelectProps } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import cn from 'classnames';
import { RawValueType } from 'rc-tree-select/lib/TreeSelect';
import { useDebouncedCallback } from 'use-debounce';

import { EmptyState } from '@atoms/emptyState/EmptyState';
import { Loader } from '@atoms/loader/Loader';
import { SelectPrefix } from '@atoms/selectPrefix/SelectPrefix';
import { SvgArrow } from 'components/icons';

import styles from './TreeSelect.module.less';

const highlightSearchResults = (title: string, searchValue: string) => {
    const trimmedSearchValue = searchValue.trim();

    if (!trimmedSearchValue) {
        return title;
    }

    const index = title.toLowerCase().indexOf(trimmedSearchValue.toLowerCase());
    if (index === -1) {
        return title;
    }

    const beforeStr = title.slice(0, index);
    const matchStr = title.slice(index, index + trimmedSearchValue.length);
    const afterStr = title.slice(index + trimmedSearchValue.length);

    return (
        <div title={title}>
            {beforeStr}
            <span className={styles.TreeSelectDropdown__Highlighted}>
                {matchStr}
            </span>
            {afterStr}
        </div>
    );
};

const findTitle = (node: string | { props: BaseOptionType }): string => {
    if (typeof node === 'string') {
        return node;
    } else if (node && node.props) {
        return findTitle(node.props.title);
    }
    return '';
};

export const TreeSelect = ({
    treeData,
    open,
    treeNodeFilterProp = 'title',
    placeholder = 'Please select',
    className = '',
    dropdownClassName = '',
    showSearch = true,
    disabled = false,
    loading = false,
    searchValue,
    autoClearSearchValue = true,
    treeDefaultExpandAll = false,
    treeDefaultExpandedKeys = [],
    treeCheckable = true,
    prefixIcon,
    prefixClassName,
    listHeight = 350,
    notFoundContent = (
        <EmptyState
            title="No matching items found"
            subtitle="Try changing the search criteria"
            style={{ height: `${listHeight}px`, padding: 0 }}
        />
    ),
    style = {
        width: '100%'
    },
    onSearch,
    onSelect,
    onDeselect,
    ...props
}: TreeSelectProps & {
    prefixIcon?: React.ReactNode;
    prefixClassName?: string;
}) => {
    const [formattedTreeData, setFormattedTreeData] = useState<
        BaseOptionType[] | undefined
    >(treeData);
    const [filteredTreeData, setFilteredTreeData] = useState<
        BaseOptionType[] | undefined
    >(treeData);
    const showPrefix = Boolean(prefixIcon) || showSearch;
    const [isFocused, setIsFocused] = useState(false);
    const controlledSearch = Boolean(onSearch);

    useEffect(() => {
        if (!loading) {
            const formattedData = addClassNameToRootNodes(treeData);

            setFormattedTreeData(formattedData);
            setFilteredTreeData(formattedData);
        }
    }, [loading, treeData]);

    useEffect(() => {
        if (formattedTreeData && controlledSearch) {
            void performSearch(searchValue || '');
        }
    }, [formattedTreeData, searchValue, controlledSearch]);

    const addClassNameToRootNodes = (
        data: BaseOptionType[] | undefined,
        depth = 0
    ): BaseOptionType[] | undefined => {
        if (!data) {
            return data;
        }

        return data.map((node) => {
            const isRootLevel = depth === 0;
            const className = isRootLevel ? 'root-node' : 'child-node';
            if (node.children) {
                return {
                    ...node,
                    children: addClassNameToRootNodes(node.children, depth + 1),
                    className
                };
            }

            return { ...node, className };
        });
    };

    const filterTreeData = (
        data: BaseOptionType[] | undefined,
        search: string
    ): BaseOptionType[] | undefined => {
        if (!data) {
            return data;
        }

        return data.map((node) => {
            let highlightedTitle;

            if (typeof node.title === 'string') {
                highlightedTitle = highlightSearchResults(node.title, search);
            } else if (node.title && typeof node.title === 'object') {
                highlightedTitle = React.cloneElement(node.title, {
                    ...node.title.props,
                    title: highlightSearchResults(
                        node.title.props.title,
                        search
                    )
                });
            }

            if (node.children) {
                return {
                    ...node,
                    children: filterTreeData(node.children, search),
                    title: highlightedTitle
                };
            }

            return { ...node, title: highlightedTitle };
        });
    };

    const performSearch = async (searchValue: string) => {
        startTransition(() => {
            const filteredData = filterTreeData(formattedTreeData, searchValue);
            setFilteredTreeData(filteredData);
        });
    };

    const handleSearchChange = useDebouncedCallback((value: string) => {
        if (controlledSearch) {
            if (isFocused) {
                onSearch?.(value);
            }
        } else {
            void performSearch(value);
        }
    }, 10);

    const filterTreeNode = (inputValue: string, treeNode: BaseOptionType) => {
        const title = findTitle(treeNode.title);

        return title.toLowerCase().includes(inputValue.trim().toLowerCase());
    };

    const resetHighlightingSearchResults = () => {
        const filteredData = filterTreeData(formattedTreeData, '');
        setFilteredTreeData(filteredData);
    };

    const handleSelect = (value: RawValueType, node: BaseOptionType) => {
        if (autoClearSearchValue && !onSearch) {
            resetHighlightingSearchResults();
        }
        onSelect?.(value, node);
    };

    const handleDeselect = (value: RawValueType, node: BaseOptionType) => {
        if (autoClearSearchValue && !onSearch) {
            resetHighlightingSearchResults();
        }
        onDeselect?.(value, node);
    };

    return (
        <>
            {!loading ? (
                <div
                    className={cn(styles.TreeSelectContainer, {
                        [styles.TreeSelectContainer__WithPrefix]: showPrefix,
                        [styles.TreeSelectContainer__WithControlledSearch]:
                            controlledSearch
                    })}
                >
                    {showPrefix && (
                        <SelectPrefix
                            disabled={disabled}
                            className={prefixClassName}
                        />
                    )}
                    <AntTreeSelect
                        {...props}
                        data-testid="TreeSelect"
                        open={open}
                        disabled={disabled}
                        searchValue={searchValue}
                        onSearch={handleSearchChange}
                        switcherIcon={<SvgArrow color="#273C83" />}
                        treeData={filteredTreeData}
                        treeDefaultExpandAll={treeDefaultExpandAll}
                        treeDefaultExpandedKeys={
                            treeDefaultExpandAll
                                ? undefined
                                : treeDefaultExpandedKeys
                        }
                        className={cn(styles.TreeSelect, className)}
                        dropdownClassName={cn(
                            styles.TreeSelectDropdown,
                            dropdownClassName
                        )}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        onSelect={handleSelect}
                        onDeselect={handleDeselect}
                        listHeight={listHeight}
                        treeCheckable={treeCheckable}
                        placeholder={placeholder}
                        treeNodeFilterProp={treeNodeFilterProp}
                        notFoundContent={notFoundContent}
                        style={style}
                        showSearch={showSearch}
                        filterTreeNode={filterTreeNode}
                    />
                </div>
            ) : (
                <div className={styles.LoaderContainer}>
                    <Loader size="medium" />
                </div>
            )}
        </>
    );
};
