export const addGoogleScript = (googleAnalyticsKey: string) => {
    if (googleAnalyticsKey) {
        const googleScript = document.createElement('script');
        googleScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsKey}`;
        googleScript.async = true;
        googleScript.id = 'google-analytics';
        document.body.appendChild(googleScript);

        const inlineScript = document.createElement('script');
        inlineScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAnalyticsKey}');
          `;
        document.body.appendChild(inlineScript);
    }
};
