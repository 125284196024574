import React from 'react';

import { pageHeadersList } from '@constants/pageTitles';
import { usePageTitle } from '@helpers/usePageTitle';
import { HelpCenterTemplate } from '@templates/helpCenter/HelpCenter.template';

export const HelpCenterPage = () => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.HELP_CENTER);

    return <HelpCenterTemplate />;
};
