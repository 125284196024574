import React, { ForwardedRef, forwardRef, useState } from 'react';

import { DefaultOptionType } from 'antd/lib/select';
import classNames from 'classnames';

import { SelectProps, Select } from '@atoms/select/Select';
import { FormField } from '@customTypes/form/form.types';
import { BaseFormBlock } from '@molecules/form/BaseFormBlock';
import { sortingFilteredValue } from '@organisms/utils';

import styles from './FormSelect.module.less';

export interface FormSelectProps extends FormField, SelectProps {
    isCountries?: boolean;
    className?: string;
    filterKeys?: string[];
    prefixClassName?: string;
    selectContainerClassName?: string;
    withFilterSort?: boolean;
}

export const FormSelect = forwardRef(function FormSelect(
    {
        title,
        fieldName,
        rules,
        initialValue,
        dropdownMatchSelectWidth,
        clearIcon,
        onChange,
        required,
        filterKeys = [],
        withFilterSort = true,
        prefixClassName,
        selectContainerClassName,
        ...props
    }: FormSelectProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const [inputValue, setInputValue] = useState('');
    const [caretVisible, setCaretVisible] = useState(true);

    const handleOnSearch = (value: string) => setInputValue(value.trim());

    const handleChange = (
        value: string,
        option: DefaultOptionType | DefaultOptionType[]
    ) => {
        setInputValue('');
        onChange?.(value, option);
        setCaretVisible(false);
    };

    const classNameSelect = classNames(styles.Container__Select, {
        [styles.TransparentCaret]: !caretVisible
    });

    return (
        <BaseFormBlock
            title={title}
            fieldName={fieldName}
            rules={rules}
            initialValue={initialValue}
            required={required}
            wrapperClassName={props.className}
        >
            <Select
                {...props}
                ref={ref}
                filterSort={
                    withFilterSort
                        ? sortingFilteredValue(inputValue, filterKeys)
                        : undefined
                }
                onSearch={props.showSearch ? handleOnSearch : undefined}
                onChange={handleChange}
                clearIcon={clearIcon}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                className={classNameSelect}
                containerClassName={selectContainerClassName}
                onDeselect={() => setCaretVisible(true)}
                prefixClassName={prefixClassName}
            />
        </BaseFormBlock>
    );
});
