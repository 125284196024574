import React, { useMemo, useState, useEffect } from 'react';

import { Select as AntSelect } from 'antd';

import { Country } from '@customTypes/general/general.types';
import { searchCountry } from '@helpers/searchCountry';
import { capitalize } from '@helpers/stringHelper';
import {
    FormSelect,
    FormSelectProps
} from '@molecules/form/formSelect/FormSelect';
import { countriesKeys } from '@organisms/utils';

import styles from './CountrySelect.module.less';

import 'flag-icons/css/flag-icons.min.css';

const { Option } = AntSelect;

export interface CountrySelectProps extends Partial<FormSelectProps> {
    onCountryChange?: VoidFunction;
    fieldName?: string;
    allCountries?: Country[];
}

export const CountrySelect = ({
    onCountryChange,
    fieldName = 'country',
    allCountries = [],
    ...props
}: CountrySelectProps) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    const renderCountryOption = (item: Country, i: number) => (
        <Option
            key={item.countryCode}
            value={item.countryName}
            data={item}
            className={styles.Option}
            id={`CountryOption_${i}`}
        >
            <div className={styles.Option__ContentWrapper}>
                {loading ? (
                    <div className={styles.Option__FlagPlaceholder} />
                ) : (
                    <span
                        className={`fi fi-${item.countryCode.toLowerCase()}`}
                        id={`CountryFlag_${i}`}
                    />
                )}
                <span id={`CountryName_${i}`} className={styles.CountryName}>
                    {capitalize(item.countryName)}
                </span>
            </div>
            <span id={`CountryCode_${i}`} className={styles.CountryCode}>
                {item.countryCode}
            </span>
        </Option>
    );

    const countryOptions = useMemo(
        () => allCountries.map(renderCountryOption),
        [allCountries, loading]
    );

    return (
        <FormSelect
            {...props}
            filterKeys={countriesKeys}
            fieldName={fieldName}
            title="Country"
            showSearch={true}
            virtual={false}
            allowClear
            filterOption={(inputValue, option) =>
                searchCountry(inputValue.trim(), option, false)
            }
            placeholder="Select Country"
            className={styles.CountrySelect}
            onChange={onCountryChange}
            dropdownRender={(menu) => (
                <div className={styles.CountrySelect__ListContent}>{menu}</div>
            )}
            data={countryOptions}
        />
    );
};
