import { MutableRefObject, useEffect, useState } from 'react';

import { useGetNotificationsMutation } from '@apis/notifications/Notifications.api';
import {
    NotificationRequest,
    NotificationType
} from '@customTypes/notifications/notifications.type';
import { useNotificationScroll } from '@hooks/useNotificationScroll';
import { useUserSettings } from '@hooks/useUserSettings';
import { useSignalR } from '@signalR/SignalR';
import { RECEIVE_EVENTS, HUBS } from '@signalR/types';
import {
    addNewNotification,
    resetNotificationsState
} from '@store/slices/notifications/notifications';
import { useAppDispatch, useAppSelector } from 'store';

const DEFAULT_REQUEST_PAYLOAD = {
    sorts: '-CreatedDate',
    page: 1,
    pageSize: 5
};

interface Props {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
    scrollRef: MutableRefObject<HTMLDivElement | null>;
    topNotificationRef: MutableRefObject<NotificationType | null>;
}

export const useNotificationPanel = ({
    isOpen,
    setIsOpen,
    scrollRef
}: Props) => {
    const { dateFormatKey } = useUserSettings();
    const dispatch = useAppDispatch();
    const [getNotifications] = useGetNotificationsMutation();
    const [notificationRequest, setNotificationRequest] =
        useState<NotificationRequest | null>(null);

    const {
        handleScrollToTop,
        isScrollToTopVisible,
        setTopNotificationId,
        scrollToTopNotification,
        onNewNotificationToTopChange
    } = useNotificationScroll({
        isOpen,
        scrollRef
    });

    const {
        data: notifications,
        newData: newNotifications,
        loading,
        totalCount
    } = useAppSelector(
        ({ notifications: { data, newData, totalCount, loading } }) => ({
            data,
            newData,
            totalCount,
            loading
        })
    );

    const showLoader = !notifications.length && loading;

    const getNotificationsData = () => {
        const request = getNotifications(DEFAULT_REQUEST_PAYLOAD);
        setNotificationRequest(request);
    };

    const handleOpenChange = (newOpen: boolean) => {
        setIsOpen(newOpen);
        if (!newOpen) {
            notificationRequest?.abort();
            dispatch(resetNotificationsState());
        }
    };

    const handleLoadMore = () => {
        if (!loading) {
            const nextPage =
                Math.ceil(
                    notifications.length / DEFAULT_REQUEST_PAYLOAD.pageSize
                ) + 1;

            const firstNotification = notifications[0];

            getNotifications({
                ...DEFAULT_REQUEST_PAYLOAD,
                page: nextPage,
                filters: firstNotification
                    ? `UserNotificationId<=${firstNotification[0].userNotificationId}`
                    : ''
            });
        }
    };

    const {
        openConnection: openNewNotificationConnection,
        closeConnection: closeNewNotificationConnection
    } = useSignalR<NotificationType>({
        hub: HUBS.user.orderStatusNotificationHub,
        eventCallBack: (newNotification) => {
            onNewNotificationToTopChange();
            dispatch(addNewNotification(newNotification));
        },
        eventName: RECEIVE_EVENTS.RECEIVE_ORDER_STATUS_NOTIFICATION
    });

    useEffect(() => {
        if (isOpen) {
            getNotificationsData();
            openNewNotificationConnection();
        } else {
            closeNewNotificationConnection();
            dispatch(resetNotificationsState());
            notificationRequest?.abort();
        }
    }, [isOpen]);

    useEffect(() => {
        if (notifications.length) {
            const topNotificationId = String(
                notifications[0][0].userNotificationId
            );
            setTopNotificationId(topNotificationId);
        }
    }, [notifications]);

    useEffect(() => {
        if (newNotifications.length) {
            scrollToTopNotification();
        }
    }, [newNotifications]);

    return {
        totalCount,
        loading,
        notifications,
        newNotifications,
        dateFormatKey,
        showLoader,
        isScrollToTopVisible,
        handleScrollToTop,
        handleLoadMore,
        handleOpenChange
    };
};
