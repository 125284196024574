import { DateTime } from 'luxon';

import { DATE_FORMAT } from '@apis/users/UsersApi.types';
import { NotificationOrderData } from '@customTypes/notifications/notifications.type';

interface GetTimeProps {
    date: string;
    dateFormatKey?: DATE_FORMAT;
}

export const getTimeAgo = ({ date, dateFormatKey }: GetTimeProps) => {
    const isMonthDayYearFormat = dateFormatKey === DATE_FORMAT.MONTH_DAY_YEAR;
    const now = DateTime.now();
    const deliveryTime = DateTime.fromISO(date, {
        zone: 'utc'
    });
    const diff = now.diff(deliveryTime);

    if (diff.as('milliseconds') < 60000) {
        return 'Just now';
    } else if (diff.as('minutes') < 60) {
        const minutes = Math.floor(diff.as('minutes'));
        return `${minutes} min ago`;
    } else if (diff.as('hours') < 24) {
        const hours = Math.floor(diff.as('hours'));
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    }
    const dateFormat = isMonthDayYearFormat ? 'MMM d' : 'd MMM';
    return deliveryTime.toFormat(dateFormat);
};

export const getFormattedReferenceNumbers = (item: NotificationOrderData) => [
    {
        label: item.orderReferenceNumber1Label || 'Reference Number',
        value: item.orderReferenceNumber1Value || ''
    },
    {
        label: item.orderReferenceNumber2Label || 'Reference Number 2',
        value: item.orderReferenceNumber2Value || ''
    },
    {
        label: item.orderReferenceNumber3Label || 'Reference Number 3',
        value: item.orderReferenceNumber3Value || ''
    },
    {
        label: item.orderReferenceNumber4Label || 'Reference Number 4',
        value: item.orderReferenceNumber4Value || ''
    }
];
