import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import {
    REQUEST_PATH,
    GetMaterialsResponse,
    GetMaterialsTransformedResponse,
    GetMirReasonsResponse,
    GetMirCarriersResponse,
    GetMirAccountWarehousesResponse,
    GetMirRecordResponse,
    GetMaterialsDTO,
    DownloadMirDocumentDTO,
    GetMirStatusResponse,
    SendToMirReviewDTO
} from '@apis/materialsInReview/MaterialsInReview.types';
import { RequestStatusWithEmptyData } from '@customTypes/api/api.types';
import { downloadBlob } from '@helpers/downloadBlob';
import {
    mapMirTotalsToStatuses,
    PROGRESS_STATUSES
} from '@helpers/orderStatus';

export const materialsInReviewApi = createApi({
    reducerPath: 'materialsInReviewApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getMirRecords: builder.mutation<
            GetMaterialsTransformedResponse,
            GetMaterialsDTO
        >({
            query: (dto) => {
                return {
                    url: REQUEST_PATH.GET_MIR_RECORDS,
                    method: 'POST',
                    body: dto
                };
            },
            transformResponse: (
                response: GetMaterialsResponse
            ): GetMaterialsTransformedResponse => {
                return {
                    ...response,
                    data: {
                        ...response.data,
                        items: response.data?.items?.map((item) => ({
                            ...item,
                            id: item.mirId.toString()
                        })),
                        statusTotals: response.data?.mirStatuses
                            ? Object.entries(response.data?.mirStatuses).map(
                                  ([key, value]) => ({
                                      label: mapMirTotalsToStatuses(key),
                                      value
                                  })
                              )
                            : Object.values(PROGRESS_STATUSES).map(
                                  (status) => ({ label: status, value: 0 })
                              )
                    }
                };
            }
        }),
        getMirReasons: builder.query<GetMirReasonsResponse, void>({
            query: () => {
                return {
                    url: REQUEST_PATH.GET_MIR_REASONS
                };
            }
        }),
        getMirCarriers: builder.query<GetMirCarriersResponse, void>({
            query: () => {
                return {
                    url: REQUEST_PATH.GET_MIR_CARRIERS
                };
            }
        }),
        getMirAccountWarehouses: builder.mutation<
            GetMirAccountWarehousesResponse,
            void
        >({
            query: () => ({
                url: REQUEST_PATH.GET_MIR_ACCOUNT_WAREHOUSES,
                method: 'POST',
                body: {}
            })
        }),
        getMirRecord: builder.query<GetMirRecordResponse, string>({
            query: (id) => ({
                url: REQUEST_PATH.GET_MIR_RECORD.replace(':id', id),
                method: 'GET'
            })
        }),
        getMirStatus: builder.query<GetMirStatusResponse, string>({
            query: (id) => {
                return {
                    url: REQUEST_PATH.GET_MIR_STATUS.replace(':id', id),
                    method: 'GET'
                };
            },
            extraOptions: {
                flowStart: true
            }
        }),
        getDownloadMirDocument: builder.mutation<File, DownloadMirDocumentDTO>({
            query: (dto) => ({
                url: REQUEST_PATH.GET_MIR_DOCUMENT.replace(':id', dto.id),
                headers: {
                    responseType: 'blob',
                    'content-type': 'application/octet-stream'
                },
                responseHandler: async (response) =>
                    downloadBlob(response, dto.fileName)
            })
        }),
        sendToMirReview: builder.mutation<
            RequestStatusWithEmptyData,
            SendToMirReviewDTO
        >({
            query: (dto) => {
                return {
                    url: REQUEST_PATH.SEND_TO_MIR_REVIEW,
                    method: 'POST',
                    body: dto
                };
            },
            extraOptions: {
                flowEnd: true
            }
        })
    })
});

export const {
    useGetMirRecordsMutation,
    useGetMirReasonsQuery,
    useGetMirCarriersQuery,
    useGetMirAccountWarehousesMutation,
    useLazyGetMirRecordQuery,
    useLazyGetMirStatusQuery,
    useGetDownloadMirDocumentMutation,
    useSendToMirReviewMutation
} = materialsInReviewApi;
