import { B2CController } from '@configs/B2CController';
import { FeatureFlagsController } from '@configs/FeatureFlagsController';
import {
    AppConfigType,
    MainConfigType
} from '@customTypes/general/appConfig.types';

export class AppController {
    protected static instance: AppController;

    public config: MainConfigType | null = null;
    private b2cInstance: B2CController = B2CController.getInstance();
    private featureFlagsController: FeatureFlagsController =
        FeatureFlagsController.getInstance();

    public static getInstance(): AppController {
        if (!AppController.instance) {
            AppController.instance = new AppController();
        }

        return AppController.instance;
    }

    public setConfig(config: AppConfigType): void {
        if (config) {
            if (config.appConfig) {
                this.b2cInstance.setB2CData(config.appConfig.b2c);
            }

            if (config.featureFlags) {
                this.featureFlagsController.config = config.featureFlags;
            }
        }
        this.config = config.appConfig;
    }
}
