import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
    inboundByBulkApi,
    inboundByBulkDefectiveApi
} from '@apis/inboundByBulk/InboundByBulk.api';
import {
    outboundByBulkApi,
    outboundByBulkDefectiveApi
} from '@apis/outboundByBulk/OutboundByBulk.api';
import {
    transfersByBulkApi,
    transfersByBulkDefectiveApi
} from '@apis/transfersByBulk/TransfersByBulk.api';
import { RequestStatusWithEmptyData } from '@customTypes/api/api.types';
import {
    BULK_TYPES,
    ColumnMappingResponse,
    DELIVERY_TYPES_VALUES,
    DeliveryType,
    GroupedOrder,
    ManuallyMappedItem,
    MappingResultsItem,
    SelectedServiceType,
    ValidateFileData,
    ValidateFileError,
    ValidateFileResponse
} from '@customTypes/bulkOrder/bulk.types';
import { ByChoiceService } from '@customTypes/deliveryServices/deliveryServices.types';
import { Action } from 'store/index';

export interface BulkState {
    activeStep: number;
    mappedColumns: MappingResultsItem[];
    manualMappedColumns: ManuallyMappedItem[];
    unmappedColumns: string[];
    redirectBlocker: boolean;
    uploadStepFormCompleted: boolean;
    fileData?: string;
    columnsLoading: boolean;
    validatedFileData: ValidateFileData[];
    validatedFileErrors: ValidateFileError[];
    fileValidationProgress: number;
    submitBulkOrderProgress: number;
    fileValidationId: string;
    orderListId: string;
    groupedOrders: GroupedOrder[];
    bulkSubmit: RequestStatusWithEmptyData | null;
    deliveryMethod?: DELIVERY_TYPES_VALUES;
    bulkType: BULK_TYPES | null;
    isBrowserBackButtonBlocked: boolean;
    delivery: DeliveryType[];
    selectedService?: SelectedServiceType;
    carriers?: ByChoiceService[];
    carriersLoading?: boolean;
    servicesError?: Omit<RequestStatusWithEmptyData, 'data'> | null;
    returnPackAvailability?: boolean;
}

const initialState: BulkState = {
    activeStep: 1,
    fileData: undefined,
    mappedColumns: [],
    unmappedColumns: [],
    redirectBlocker: false,
    uploadStepFormCompleted: false,
    columnsLoading: false,
    manualMappedColumns: [],
    validatedFileErrors: [],
    validatedFileData: [],
    fileValidationProgress: 0,
    submitBulkOrderProgress: 0,
    fileValidationId: '',
    groupedOrders: [],
    orderListId: '',
    bulkSubmit: null,
    bulkType: null,
    isBrowserBackButtonBlocked: false,
    delivery: []
};

const setColumnMappingsResult = (
    state: BulkState,
    { payload }: PayloadAction<ColumnMappingResponse>
) => {
    state.mappedColumns = payload.data?.mappingResults;
    state.manualMappedColumns = payload.data?.mappingResults;
    state.unmappedColumns = payload.data?.unmappedHeaders;
    state.columnsLoading = false;
};

const setValidationResult = (
    state: BulkState,
    { payload }: PayloadAction<ValidateFileResponse>
) => {
    state.validatedFileErrors = payload.data?.errors;
    state.validatedFileData = payload.data?.fileData;
    state.fileValidationId = payload.data?.fileValidationId;
};

export const bulkSlice = createSlice({
    name: 'bulk',
    initialState,
    reducers: {
        setActiveStep(state, action: Action<number>) {
            state.activeStep = action.payload;
        },
        resetColumns(state) {
            state.mappedColumns = [];
            state.unmappedColumns = [];
        },
        setManualMappedColumns(state, action: Action<ManuallyMappedItem[]>) {
            state.manualMappedColumns = action.payload;
        },
        setFileData(state, action: Action<string | undefined>) {
            state.fileData = action.payload;
        },
        setRedirectBlocker(state, action: Action<boolean>) {
            state.redirectBlocker = action.payload;
        },
        setUploadStepFormState(state, action: Action<boolean>) {
            state.uploadStepFormCompleted = action.payload;
        },
        setFileValidationProgress(state, action: Action<number>) {
            state.fileValidationProgress = action.payload;
        },
        setBulkSubmit(
            state,
            action: Action<RequestStatusWithEmptyData | null>
        ) {
            state.bulkSubmit = action.payload;
        },
        setGroupedOrders(state, action: Action<GroupedOrder[]>) {
            state.groupedOrders = action.payload;
        },
        setOrderListId(state, action: Action<string>) {
            state.orderListId = action.payload;
        },
        setSubmitBulkOrdersProgress(state, action: Action<number>) {
            state.submitBulkOrderProgress = action.payload;
        },
        resetBulkState() {
            return initialState;
        },
        resetBulkStateKeepDeliveryMethod({
            deliveryMethod,
            redirectBlocker,
            bulkType
        }) {
            return {
                ...initialState,
                deliveryMethod,
                redirectBlocker,
                bulkType
            };
        },
        setDeliveryMethod(state, action: Action<DELIVERY_TYPES_VALUES>) {
            state.deliveryMethod = action.payload;
        },
        setReturnPackAvailability(state, action: Action<boolean>) {
            state.returnPackAvailability = action.payload;
        },
        resetDeliveryMethod(state) {
            state.deliveryMethod = initialState.deliveryMethod;
        },
        setBulkType(state, action: Action<BULK_TYPES>) {
            state.bulkType = action.payload;
        },
        setService(state, action: Action<SelectedServiceType>) {
            state.selectedService = action.payload;
        },
        resetSelectedService(state) {
            state.selectedService = initialState.selectedService;
        },
        setDelivery(state, action: Action<DeliveryType>) {
            state.delivery = [...state.delivery, action.payload];
        },
        resetDelivery(state) {
            state.delivery = initialState.delivery;
        },
        setServicesError(state, action) {
            state.servicesError = {
                statusCode: action.payload.statusCode,
                statusMessage: action.payload.statusMessage
            };
        },
        resetServicesError(state) {
            state.servicesError = null;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            inboundByBulkApi.endpoints.getInboundColumnsMapping.matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            inboundByBulkApi.endpoints.getInboundColumnsMapping.matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            inboundByBulkApi.endpoints.getInboundColumnsMapping.matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            inboundByBulkApi.endpoints.validateInboundFile.matchFulfilled,
            setValidationResult
        );
        builder.addMatcher(
            inboundByBulkDefectiveApi.endpoints.getInboundColumnsMapping
                .matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            inboundByBulkDefectiveApi.endpoints.getInboundColumnsMapping
                .matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            inboundByBulkDefectiveApi.endpoints.getInboundColumnsMapping
                .matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            inboundByBulkDefectiveApi.endpoints.validateInboundFile
                .matchFulfilled,
            setValidationResult
        );

        builder.addMatcher(
            outboundByBulkApi.endpoints.getOutboundColumnsMapping.matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            outboundByBulkApi.endpoints.getOutboundColumnsMapping.matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            outboundByBulkApi.endpoints.getOutboundColumnsMapping
                .matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            outboundByBulkApi.endpoints.validateOutboundFile.matchFulfilled,
            setValidationResult
        );
        builder.addMatcher(
            outboundByBulkDefectiveApi.endpoints.getOutboundColumnsMapping
                .matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            outboundByBulkDefectiveApi.endpoints.getOutboundColumnsMapping
                .matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            outboundByBulkDefectiveApi.endpoints.getOutboundColumnsMapping
                .matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            outboundByBulkDefectiveApi.endpoints.validateOutboundFile
                .matchFulfilled,
            setValidationResult
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.getTransfersColumnsMapping
                .matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.getTransfersColumnsMapping
                .matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.getTransfersColumnsMapping
                .matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.validateTransfersFile.matchFulfilled,
            setValidationResult
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.validateTransfersFile
                .matchFulfilled,
            setValidationResult
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.getAvailableCarriers.matchPending,
            (state) => {
                state.carriersLoading = true;
            }
        );
        builder.addMatcher(
            transfersByBulkApi.endpoints.getAvailableCarriers.matchRejected,
            (state) => {
                state.carriersLoading = false;
            }
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.getTransfersColumnsMapping
                .matchPending,
            (state) => {
                state.columnsLoading = true;
            }
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.getTransfersColumnsMapping
                .matchRejected,
            (state) => {
                state.columnsLoading = false;
            }
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.getTransfersColumnsMapping
                .matchFulfilled,
            setColumnMappingsResult
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.getAvailableCarriers
                .matchPending,
            (state) => {
                state.carriersLoading = true;
            }
        );
        builder.addMatcher(
            transfersByBulkDefectiveApi.endpoints.getAvailableCarriers
                .matchRejected,
            (state) => {
                state.carriersLoading = false;
            }
        );
    }
});

export const {
    setActiveStep,
    resetColumns,
    resetBulkState,
    setRedirectBlocker,
    setFileData,
    setUploadStepFormState,
    setManualMappedColumns,
    setFileValidationProgress,
    setBulkSubmit,
    setSubmitBulkOrdersProgress,
    setGroupedOrders,
    setOrderListId,
    setDeliveryMethod,
    resetBulkStateKeepDeliveryMethod,
    setBulkType,
    resetDeliveryMethod,
    setService,
    setDelivery,
    resetDelivery,
    setServicesError,
    resetServicesError,
    setReturnPackAvailability,
    resetSelectedService
} = bulkSlice.actions;
