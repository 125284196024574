import React from 'react';

import { pageHeadersList } from '@constants/pageTitles';
import { usePageTitle } from '@helpers/usePageTitle';
import { HomeTemplate } from '@templates/home/Home.template';

export const HomePage = () => {
    usePageTitle(pageHeadersList.HOME_PAGE.ROOT);

    return <HomeTemplate />;
};
