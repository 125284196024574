import { Dispatch, SetStateAction, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { RoutesList } from '@constants/routesList';
import { RedirectListType } from '@customTypes/navigation/navigation.types';
import { useModal } from '@hooks/useModal';
import { RedirectList } from '@redirect/RedirectList';
import { useAppDispatch } from 'store';

export interface ConfirmRedirectReturnType {
    isModalOpen: boolean;
    modalToggle: VoidFunction;
    onOkRedirect: VoidFunction;
    redirectPath: string;
    setRedirectPath: Dispatch<SetStateAction<string>>;
}

export const useConfirmRedirect = (
    signOut: VoidFunction
): ConfirmRedirectReturnType => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isOpen: isModalOpen, toggle: modalToggle, closeModal } = useModal();
    const [redirectPath, setRedirectPath] = useState('');

    const onOkRedirect = () => {
        navigate(redirectPath);
        closeModal();

        const locationState = RedirectList.find(
            (el: RedirectListType) => redirectPath === el.redirectTo
        );

        if (locationState?.resetStateAction) {
            dispatch(locationState.resetStateAction());
        }

        if (redirectPath === RoutesList.ROOT) {
            closeModal();
            signOut();
            return;
        }
    };

    return {
        isModalOpen,
        modalToggle,
        redirectPath,
        setRedirectPath,
        onOkRedirect
    };
};
