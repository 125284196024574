import React from 'react';

import classNames from 'classnames';

import { VoidFunction } from 'utils/types/general/general.types';

import styles from './BurgerButton.module.less';

interface Props {
    isOpen: boolean;
    onClick: VoidFunction;
}

export const BurgerButton = ({ isOpen = false, onClick }: Props) => {
    const burgerButtonContent = () => {
        return [
            styles.ContentTop__Active,
            styles.ContentMiddle__Active,
            styles.ContentBottom__Active
        ].map((item, i) => (
            <div
                key={`burgerButtonContent${i}`}
                className={classNames(styles.Container__Content, {
                    [item]: isOpen
                })}
            />
        ));
    };

    return (
        <div
            data-testid="burger-button"
            className={styles.Container}
            onClick={onClick}
        >
            {burgerButtonContent()}
        </div>
    );
};
