import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import { GetKeysResponse, REQUESTS_PATH } from '@apis/google/GoogleApi.types';

export const googleApi = createApi({
    reducerPath: 'googleApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getKeys: builder.query<GetKeysResponse, void>({
            query: () => ({ url: REQUESTS_PATH.GET_KEYS })
        })
    })
});

export const { useGetKeysQuery } = googleApi;
