import React from 'react';

import { pageHeadersList } from '@constants/pageTitles';
import { usePageTitle } from '@helpers/usePageTitle';
import { AuthTemplate } from '@templates/auth/Auth.template';

export const AuthPage = () => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.AUTH_PAGE);

    return <AuthTemplate />;
};
