import { createSlice } from '@reduxjs/toolkit';

import { Action } from 'store/index';

interface InitialState {
    isLoading: boolean;
    isError: boolean;
}

const initialState: InitialState = {
    isLoading: true,
    isError: false
};

export const dundasSlice = createSlice({
    name: 'dundas',
    initialState,
    reducers: {
        setLoading(state, { payload }: Action<boolean>) {
            state.isLoading = payload;
        },
        setError(state, { payload }: Action<boolean>) {
            state.isError = payload;
        }
    }
});

export const { setLoading, setError } = dundasSlice.actions;
