import React from 'react';

import classNames from 'classnames';

import { useNotifications } from '@organisms/notifications/useNotifications';
import { SvgBellDark, SvgBellLight } from 'components/icons';
import { Badge } from 'components/UI/atoms/badge/Badge';
import { NotificationsPanel } from 'components/UI/organisms/notificationsPanel/NotificationsPanel';

import styles from './Notifications.module.less';

export const Notifications = () => {
    const {
        isOpen,
        setIsOpen,
        goToDetailsPage,
        markAllAsRead,
        unreadCounter,
        isMobileVersion
    } = useNotifications();

    return (
        <div
            className={styles.NotificationsContainer}
            data-testid="notificationsContainer"
        >
            <NotificationsPanel
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                goToDetailsPage={goToDetailsPage}
                markAllAsRead={markAllAsRead}
                unreadCounter={unreadCounter}
            >
                <div
                    id="badge"
                    data-testid="counterBadge"
                    className={classNames(styles.Row, {
                        [styles.Badge__Empty]: !unreadCounter
                    })}
                >
                    <Badge
                        offset={[-4, 10]}
                        overflowCount={999}
                        count={unreadCounter}
                        node={
                            isMobileVersion ? <SvgBellLight /> : <SvgBellDark />
                        }
                    />
                </div>
            </NotificationsPanel>
        </div>
    );
};
