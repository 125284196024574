import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions.d';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AxiosProgressEvent } from 'axios';

import { axiosQueryWithIntercept } from '@apis/axiosBaseQuery';
import {
    AddDocumentResponse,
    UploadFileDTO,
    UploadFileResponse,
    REQUESTS_PATH
} from '@apis/files/Files.type';
import { QueryWithIntercept } from '@apis/queryWithIntercept';
import { RequestStatusWithEmptyData } from '@customTypes/api/api.types';
import store from '@store/index';
import { setUploadProgress } from '@store/slices/files/files';

const initialConfig: {
    cache: RequestCache;
    method: string;
    headers: Record<string, string>;
} = {
    method: 'POST',
    cache: 'no-cache',
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

const uploadFile = (
    builder: EndpointBuilder<QueryWithIntercept, never, 'filesApi'>,
    url: REQUESTS_PATH,
    flowStart?: boolean
) => {
    return builder.mutation<UploadFileResponse, UploadFileDTO>({
        query: ({ file }) => ({
            method: 'POST',
            url: url,
            body: file,
            cache: 'no-cache'
        }),
        extraOptions: {
            onUploadProgress: ({ loaded, total }: AxiosProgressEvent) => {
                if (total) {
                    const uploadProgress = Math.round((100 * loaded) / total);
                    store.dispatch(setUploadProgress(uploadProgress));
                }
            },
            flowStart: Boolean(flowStart)
        }
    });
};

export const filesApi = createApi({
    reducerPath: 'filesApi',
    baseQuery: axiosQueryWithIntercept,
    endpoints: (builder) => ({
        uploadInboundFile: uploadFile(
            builder,
            REQUESTS_PATH.UPLOAD_INBOUND_FILE,
            true
        ),
        uploadDefectiveInboundFile: uploadFile(
            builder,
            REQUESTS_PATH.UPLOAD_DEFECTIVE_INBOUND_FILE,
            true
        ),
        uploadOutboundFile: uploadFile(
            builder,
            REQUESTS_PATH.UPLOAD_OUTBOUND_FILE,
            true
        ),
        uploadDefectiveOutboundFile: uploadFile(
            builder,
            REQUESTS_PATH.UPLOAD_DEFECTIVE_OUTBOUND_FILE,
            true
        ),
        uploadTransfersFile: uploadFile(
            builder,
            REQUESTS_PATH.UPLOAD_TRANSFERS_FILE,
            true
        ),
        uploadDefectiveTransfersFile: uploadFile(
            builder,
            REQUESTS_PATH.UPLOAD_DEFECTIVE_TRANSFERS_FILE,
            true
        ),
        addTicketDocument: builder.mutation<AddDocumentResponse, FormData>({
            query: (formData) => ({
                ...initialConfig,
                url: REQUESTS_PATH.ADD_DOCUMENT,
                body: formData
            })
        }),
        updateTicketDocument: builder.mutation<AddDocumentResponse, FormData>({
            query: (formData) => ({
                ...initialConfig,
                url: REQUESTS_PATH.UPDATE_DOCUMENT,
                body: formData
            })
        }),
        addMirDocument: builder.mutation<RequestStatusWithEmptyData, FormData>({
            query: (formData) => ({
                method: 'POST',
                url: REQUESTS_PATH.ADD_MIR_DOCUMENT,
                body: formData
            })
        })
    })
});

export const {
    useUploadInboundFileMutation,
    useUploadDefectiveInboundFileMutation,
    useUploadOutboundFileMutation,
    useUploadDefectiveOutboundFileMutation,
    useUploadTransfersFileMutation,
    useUploadDefectiveTransfersFileMutation,
    useAddTicketDocumentMutation,
    useUpdateTicketDocumentMutation,
    useAddMirDocumentMutation
} = filesApi;
