import React from 'react';

import { EmptyAccount } from 'components/UI/atoms/emptyAccount/EmptyAccount';
import { useUser } from 'utils/hooks/useUser';

interface Props {
    children: React.ReactNode;
}

export const WithAccountWrapper: React.FC<Props> = ({ children }: Props) => {
    const { accountNumber } = useUser();

    if (!accountNumber) {
        return <EmptyAccount />;
    }
    return <>{children}</>;
};
