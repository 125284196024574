import React from 'react';

import { Form } from 'antd';

import { RadioButton, RadioGroup } from 'components/UI/atoms/radio/Radio';
import { ListItem } from 'utils/types/general/general.types';

import styles from './CustomRadioGroup.module.less';

export interface Props {
    data: ListItem[];
    name: string;
}

export const CustomRadioGroup = ({ data, name }: Props) => (
    <Form.Item name={name} id={name}>
        <RadioGroup
            data-testid="custom-radio-group"
            className={styles.CustomRadioGroup}
            buttonStyle="solid"
        >
            {data.map(({ label, value }) => (
                <RadioButton
                    key={value}
                    className={styles.CustomRadioGroup__RadioButton}
                    value={value}
                >
                    {label}
                </RadioButton>
            ))}
        </RadioGroup>
    </Form.Item>
);
