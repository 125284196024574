import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions.d';
import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import {
    AvailableMaterialServicesDTO,
    REQUESTS_PATH,
    REQUESTS_PATH_DEFECTIVE
} from '@apis/inboundReplenishment/InboundReplenishment.type';
import { QueryWithIntercept } from '@apis/queryWithIntercept';
import {
    GetAccountWarehouseDTO,
    GetWarehouseResponse,
    GetWarehouseTransformedResponse
} from '@apis/warehouse/WarehouseApi.types';
import {
    GetBasicDtoPagination,
    OrderReferenceLabelsResponse,
    RequestStatusWithEmptyData,
    SubmitOrderDTO
} from '@customTypes/api/api.types';
import { AvailableServicesResponse } from '@customTypes/deliveryServices/deliveryServices.types';
import { GetPartTransformedResponse } from '@customTypes/general/part.types';
import { OrderReferenceLabelsDTO } from '@customTypes/order/order.types';
import { getServicesWithIds } from '@helpers/activeOrder';
import { getTransformedDataWithHazard } from '@helpers/apiFormatter';
import { getBasePaginationBody } from '@helpers/getBasePaginationBody';

const getCommonEndpoints = (
    builder: EndpointBuilder<QueryWithIntercept, string, string>,
    requestsPath: typeof REQUESTS_PATH | typeof REQUESTS_PATH_DEFECTIVE
) => ({
    getPartsMaterials: builder.mutation<
        GetPartTransformedResponse,
        GetBasicDtoPagination
    >({
        query: (dto) => {
            return {
                url: requestsPath.PARTS,
                method: 'POST',
                body: getBasePaginationBody({
                    ...dto,
                    filterFields: 'CustomDescription',
                    fixedSorter: 'partNumber'
                })
            };
        },
        extraOptions: {
            flowStart: true
        },
        transformResponse: getTransformedDataWithHazard
    }),
    getAccountWarehouse: builder.mutation<
        GetWarehouseTransformedResponse,
        GetAccountWarehouseDTO
    >({
        query(dto) {
            return {
                url: requestsPath.ACCOUNT_WAREHOUSE,
                method: 'POST',
                body: dto
            };
        },
        transformResponse: (
            response: GetWarehouseResponse
        ): GetWarehouseTransformedResponse => {
            return {
                ...response,
                data: {
                    available: response.data?.available?.map((item) => ({
                        ...item,
                        id: item.sslId.toString()
                    })),
                    unavailable: response.data?.unavailable?.map((item) => ({
                        ...item,
                        id: item.sslId.toString()
                    }))
                }
            };
        }
    }),
    getMaterialsReferenceNumberLabels: builder.mutation<
        OrderReferenceLabelsResponse,
        OrderReferenceLabelsDTO
    >({
        query: (dto) => ({
            url: requestsPath.REFERENCE_LABELS,
            method: 'POST',
            body: dto
        })
    }),
    submitInboundReplenishment: builder.mutation<
        RequestStatusWithEmptyData,
        SubmitOrderDTO
    >({
        query: (dto) => ({
            url: requestsPath.SUBMIT,
            method: 'POST',
            body: dto
        }),
        extraOptions: {
            flowEnd: true
        }
    }),
    getServices: builder.mutation<
        AvailableServicesResponse,
        AvailableMaterialServicesDTO
    >({
        query: (dto) => ({
            url: requestsPath.GET_SERVICES,
            method: 'POST',
            body: dto
        }),
        transformResponse(response: AvailableServicesResponse) {
            return getServicesWithIds(response) as AvailableServicesResponse;
        }
    })
});

export const inboundReplenishmentApi = createApi({
    reducerPath: 'inboundReplenishmentApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => getCommonEndpoints(builder, REQUESTS_PATH)
});
export const inboundOrderByPartDefectiveApi = createApi({
    reducerPath: 'inboundOrderByPartDefectiveApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => getCommonEndpoints(builder, REQUESTS_PATH_DEFECTIVE)
});

export const {
    useGetPartsMaterialsMutation,
    useSubmitInboundReplenishmentMutation,
    useGetMaterialsReferenceNumberLabelsMutation,
    useGetServicesMutation,
    useGetAccountWarehouseMutation
} = inboundReplenishmentApi;

export const {
    useGetPartsMaterialsMutation: useDefectiveGetPartsMaterialsMutation,
    useSubmitInboundReplenishmentMutation:
        useDefectiveSubmitInboundReplenishmentMutation,
    useGetMaterialsReferenceNumberLabelsMutation:
        useDefectiveGetMaterialsReferenceNumberLabelsMutation,
    useGetServicesMutation: useDefectiveGetServicesMutation,
    useGetAccountWarehouseMutation: useDefectiveGetAccountWarehouseMutation
} = inboundOrderByPartDefectiveApi;
