import React from 'react';

import { useLocation } from 'react-router-dom';

import {
    CancelCreateMessage,
    CancelDefaultMessage,
    CancelEditMessage,
    CancelMessage,
    CancelOrderMessage
} from '@constants/messages';
import { RoutesList } from '@constants/routesList';
import {
    ConfirmModal,
    ConfirmModalProps
} from '@molecules/confirmModal/ConfirmModal';

const { ORDERS, REPLENISHMENTS, ADMIN_PANEL, TRANSFERS, RETURNS } = RoutesList;

const getPanelPageCancelTitle = (page: string) => {
    const pageTitleMap = {
        [ADMIN_PANEL.ADMINS.CREATE.FULL]: 'Admin',
        [ADMIN_PANEL.CHOICE_ADMIN.CREATE.FULL]: 'Choice Admin',
        [ADMIN_PANEL.USERS.CREATE.FULL]: 'User',
        [ADMIN_PANEL.PROFILES.CREATE.FULL]: 'Profile'
    };
    return pageTitleMap[page] || null;
};

const getBlockerText = (page: string, isEditProfilesMode: boolean) => {
    const isOrderCancellation =
        page.includes(ORDERS.ROOT) ||
        page.includes(REPLENISHMENTS.ROOT) ||
        page.includes(TRANSFERS.ROOT) ||
        page.includes(RETURNS.ROOT);

    if (isOrderCancellation) {
        return {
            mainText: 'Cancel the order?',
            description: CancelOrderMessage,
            submitText: 'Confirm'
        };
    }

    const isAdminCancellation = page.includes(ADMIN_PANEL.ROOT);

    if (isAdminCancellation) {
        const panelPageCancelTitle = getPanelPageCancelTitle(page);
        return {
            mainText: panelPageCancelTitle
                ? `Cancel new ${panelPageCancelTitle} creation?`
                : CancelMessage,
            description: isEditProfilesMode
                ? CancelEditMessage
                : CancelCreateMessage,
            submitText: 'Confirm'
        };
    }

    return {
        mainText: CancelMessage,
        description: CancelDefaultMessage,
        submitText: 'Confirm'
    };
};

type Props = Omit<ConfirmModalProps, 'mainText' | 'submitText'>;

export const ConfirmRedirectModal = (props: Props) => {
    const { pathname } = useLocation();
    const isEditProfilesMode = pathname.includes(
        ADMIN_PANEL.PROFILES.EDIT.FULL
    );
    const { mainText, description, submitText } = getBlockerText(
        pathname.substring(1),
        isEditProfilesMode
    );

    return (
        <ConfirmModal
            mainText={mainText}
            submitText={submitText}
            description={description}
            idModalType={pathname}
            {...props}
        />
    );
};
