import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { useGetHomeQuickLinksQuery } from '@apis/users/Users.api';
import { TransformedQuickAccessResponse } from '@apis/users/UsersApi.types';
import { Loader } from '@atoms/loader/Loader';
import { getSelectedPermissions } from '@helpers/home';
import { QuickAccessButton } from '@molecules/quickAccessButton/QuickAccessButton';
import { SideBarItemsWithPermissions } from '@molecules/siderMenu/SiderMenuItems';
import { Customize } from '@organisms/home/customize/Customize';
import { HomeBlock } from '@organisms/home/shared/homeBlock/HomeBlock';
import { RedirectList } from '@redirect/RedirectList';

import styles from './QuickAccess.module.less';

interface Props {
    isMobileVersion: boolean;
    isDarkModeEnabled?: boolean;
}

export const QuickAccess = ({ isMobileVersion, isDarkModeEnabled }: Props) => {
    const { data: quickAccessData, isFetching } = useGetHomeQuickLinksQuery();

    const spawnButtons = () => {
        const links = getSelectedLinks();
        return links?.map((item, i) => (
            <QuickAccessButton
                key={i}
                title={item.group}
                label={item.label}
                icon={item.icon}
                redirectTo={item.redirectTo}
            />
        ));
    };

    const getSelectedLinks = () => {
        const selectedItems: TransformedQuickAccessResponse[] =
            getSelectedPermissions(quickAccessData?.data || []);

        return selectedItems.reduce((acc, item) => {
            const group = item.title;

            const sideMenuItem = SideBarItemsWithPermissions.find((el) =>
                item.groupKeys.includes(el.permission)
            );

            const link = item.permissions.map((el) => {
                return {
                    group: group,
                    label: el.displayName,
                    icon: sideMenuItem?.icon,
                    redirectTo: RedirectList.find(
                        (redirectItem) =>
                            redirectItem.permission &&
                            el.keys.includes(redirectItem.permission)
                    )?.redirectTo
                };
            });

            return [...acc, ...link];
        }, [] as { group: string; label: string; icon: ReactNode; redirectTo?: string }[]);
    };

    const spawnContent = () => {
        return getSelectedLinks().length ? (
            <div
                className={classNames(styles.Container, {
                    [styles.Container__MobileAlign]: isMobileVersion
                })}
            >
                {spawnButtons()}
            </div>
        ) : (
            <div className={styles.Container__Empty}>No quick links yet</div>
        );
    };

    return (
        <HomeBlock
            title="Quick Access"
            actionContent={
                <div
                    className={classNames(styles.QuickAccessContainer, {
                        [styles.QuickAccessContainer__MobileAlign]:
                            isMobileVersion
                    })}
                >
                    <Customize
                        items={quickAccessData?.data}
                        isDarkModeEnabled={isDarkModeEnabled}
                    />
                </div>
            }
        >
            {isFetching ? (
                <Loader size={isMobileVersion ? 'medium' : 'large'} />
            ) : (
                spawnContent()
            )}
        </HomeBlock>
    );
};
