import { AvailableServicesDTO } from '@customTypes/deliveryServices/deliveryServices.types';

export enum REQUEST_PATH {
    INVENTORY = '/WarehouseTransfers/GoodParts/SslInventory',
    ACCOUNT_SSL = '/WarehouseTransfers/GoodParts/AccountSSLs',
    GET_SERVICES = '/WarehouseTransfers/GoodParts/AvailableServices',
    ORDER_REFERENCE_LABELS = '/WarehouseTransfers/GoodParts/GetOrderReferenceNumberLabels',
    SERIAL_NUMBERS = '/WarehouseTransfers/GoodParts/GetInventoryPartSerialNumbers',
    PRE_CHECK = '/WarehouseTransfers/GoodParts/PreCheck',
    SUBMIT_FULL_ORDER = '/WarehouseTransfers/GoodParts/Submit',
    SUBMIT_REDUCED_ORDER = '/WarehouseTransfers/GoodParts/SubmitReduced'
}

export enum REQUEST_PATH_DEFECTIVE {
    INVENTORY = '/WarehouseTransfers/DefectiveParts/SslInventory',
    ACCOUNT_SSL = '/WarehouseTransfers/DefectiveParts/AccountSSLs',
    GET_SERVICES = '/WarehouseTransfers/DefectiveParts/AvailableServices',
    ORDER_REFERENCE_LABELS = '/WarehouseTransfers/DefectiveParts/GetOrderReferenceNumberLabels',
    SERIAL_NUMBERS = '/WarehouseTransfers/DefectiveParts/GetInventoryPartSerialNumbers',
    PRE_CHECK = '/WarehouseTransfers/DefectiveParts/PreCheck',
    SUBMIT_FULL_ORDER = '/WarehouseTransfers/DefectiveParts/Submit',
    SUBMIT_REDUCED_ORDER = '/WarehouseTransfers/DefectiveParts/SubmitReduced'
}

export interface AvailableTransferServiceDTO
    extends Omit<AvailableServicesDTO, 'consigneeAddress'> {
    consigneeSsl: string;
}
