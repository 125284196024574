import React, { useState } from 'react';

import classNames from 'classnames';

import { TransformedTrackOrder } from '@apis/trackOrders/TrackOrdersApi.types';
import { ToolTip } from '@atoms/tooltip/ToolTip';
import { useWatchListItems } from '@organisms/home/watchList/watchListItems/useWatchListItems';
import styles from '@organisms/home/watchList/watchListItems/WatchListItems.module.less';
import { renderOriginDestination } from '@organisms/trackOrders/orderList/columns';
import { ReferenceNumbers } from '@organisms/trackOrders/orderList/shared/referenceNumbers/ReferenceNumbers';
import { SvgArrowFullRight, SvgOrderWatchlist } from 'components/icons';

interface Props {
    watchlistItems?: TransformedTrackOrder[];
    isLoading?: boolean;
    isDarkModeEnabled?: boolean;
}

export interface AddedWatchlistItems {
    watchlistItemId: string;
    isInOrderWatchList: boolean;
}

export const WatchListItems = ({
    watchlistItems,
    isLoading,
    isDarkModeEnabled
}: Props) => {
    const [addedToWatchListItems, setAddedToWatchListItems] = useState<
        AddedWatchlistItems[]
    >([]);
    const {
        toggleWatchList,
        renderDateInfo,
        goToDetailsPage,
        checkMatchedFlagItems,
        renderWatchListItemStatus,
        spawnLoaderSection
    } = useWatchListItems({
        watchlistItems,
        addedToWatchListItems,
        setAddedToWatchListItems
    });

    const spawnWatchListIcon = (isAddedToWatchList: boolean) => {
        const classNameForSvg = classNames(styles.WatchListIcon, {
            [styles.WatchListIcon__Selected]: isAddedToWatchList
        });

        if (isAddedToWatchList) {
            return (
                <SvgOrderWatchlist
                    className={classNameForSvg}
                    data-testid="watchList-flag-icon"
                />
            );
        }

        return (
            <SvgOrderWatchlist
                className={classNameForSvg}
                data-testid="watchList-flag-icon"
            />
        );
    };

    const spawnWatchlistHeader = () => {
        return (
            <div
                className={styles.ListTitles}
                data-testid="watchList-head-titles-section"
            >
                <div className={styles.Status}>Status</div>
                <div className={styles.Reference}>Reference Number</div>
                <div className={styles.OriginDestination}>Origin</div>
                <div className={styles.Arrow}></div>
                <div className={styles.OriginDestination}>Destination</div>
                <div className={styles.Date}>Pick Up Date</div>
                <div className={styles.Arrow}></div>
                <div className={styles.Date}>Delivery Date</div>
                <div className={styles.WatchIcon}></div>
                <div className={styles.DetailsLink}></div>
            </div>
        );
    };

    const spawnWatchListItem = () => {
        return watchlistItems?.map((item, index) => {
            return (
                <div
                    key={index}
                    className={styles.WatchListItem}
                    data-testid="watchlist-item"
                >
                    <div
                        className={styles.ListItem}
                        data-testid="list-item-section"
                    >
                        <div className={styles.Status}>
                            {renderWatchListItemStatus(item.status)}
                        </div>
                        <div className={styles.Reference}>
                            <ReferenceNumbers
                                referenceNumbers={item.referenceNumbers}
                            />
                        </div>
                        <div className={styles.OriginDestination}>
                            {renderOriginDestination({
                                originDestination: item.origin
                            })}
                        </div>
                        <div className={styles.Arrow}>
                            <SvgArrowFullRight
                                color="#8e94a9"
                                data-testid="arrow-right-icon"
                            />
                        </div>
                        <div className={styles.OriginDestination}>
                            {renderOriginDestination({
                                originDestination: item.destination
                            })}
                        </div>
                        <div className={styles.Date}>
                            <ToolTip
                                overlayClassName={styles.Tooltip}
                                title={renderDateInfo({
                                    dateActual: item?.pickUpDateActual,
                                    dateEstimated: item.pickUpDateEstimated,
                                    dateTimeZone: item.pickUpDateTimeZone
                                })}
                                placement="bottom"
                                data-testid="pick-up-date-tooltip"
                            >
                                <div>
                                    {renderDateInfo({
                                        dateActual: item?.pickUpDateActual,
                                        dateEstimated: item.pickUpDateEstimated,
                                        dateTimeZone: item.pickUpDateTimeZone,
                                        isTimeZone: false
                                    })}
                                </div>
                            </ToolTip>
                        </div>
                        <div className={styles.Arrow}>
                            <SvgArrowFullRight
                                color="#8e94a9"
                                data-testid="arrow-right-icon"
                            />
                        </div>
                        <div className={styles.Date}>
                            <ToolTip
                                overlayClassName={styles.Tooltip}
                                title={renderDateInfo?.({
                                    dateActual: item?.deliveryDateActual,
                                    dateEstimated: item.deliveryDateEstimated,
                                    dateTimeZone: item.deliveryDateTimeZone
                                })}
                                placement="bottom"
                                data-testid="delivery-date-tooltip"
                            >
                                <div>
                                    {renderDateInfo?.({
                                        dateActual: item?.deliveryDateActual,
                                        dateEstimated:
                                            item.deliveryDateEstimated,
                                        dateTimeZone: item.deliveryDateTimeZone,
                                        isTimeZone: false
                                    })}
                                </div>
                            </ToolTip>
                        </div>
                        <div
                            onClick={() =>
                                toggleWatchList(
                                    item.id,
                                    item.isInOrderWatchList
                                )
                            }
                            className={styles.WatchIcon}
                        >
                            {spawnWatchListIcon(
                                checkMatchedFlagItems({ dataItemId: item.id })
                            )}
                        </div>
                        <div
                            className={styles.DetailsLink}
                            onClick={() => goToDetailsPage(item)}
                        >
                            <span>Details</span>
                            <div className={styles.ArrowContainer}>
                                <SvgArrowFullRight
                                    color={
                                        isDarkModeEnabled
                                            ? '#b2c0f2'
                                            : '#273C83'
                                    }
                                    data-testid="arrow-right-icon"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={styles.Container}>
            {isLoading ? (
                spawnLoaderSection()
            ) : (
                <>
                    {watchlistItems &&
                        watchlistItems?.length > 0 &&
                        spawnWatchlistHeader()}
                    {spawnWatchListItem()}
                </>
            )}

            {!watchlistItems?.length && !isLoading && (
                <div
                    className={styles.NoOrders}
                    data-testid="no-orders-section"
                >
                    No orders in your watchlist yet
                </div>
            )}
        </div>
    );
};
