import { MIR_STATUSES_FOR_RESPONSE } from '@apis/materialsInReview/MaterialsInReview.types';
import { ORDER_STATUS_FROM_RESPONSE } from '@apis/trackOrders/TrackOrdersApi.types';
import { LabelProps, LabelVariants } from '@atoms/label/Label';

export enum PROGRESS_STATUSES {
    ON_TIME = 'On Time',
    DELAYED = 'Delayed',
    PENDING = 'Pending',
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled',
    TOTAL = 'Total'
}

interface GetFormattedStatusesReturn {
    newStatus: string;
    selectedStatuses: string[];
}

export enum MIR_STATUSES {
    ALL = 'All',
    CLOSED = 'Closed',
    IN_CHOICE_REVIEW = 'In Choice Review',
    IN_CLIENT_REVIEW = 'In Client Review',
    NEW_RECORD = 'New Mir Record'
}

export const FullStatusList = [
    PROGRESS_STATUSES.ON_TIME,
    PROGRESS_STATUSES.DELAYED,
    PROGRESS_STATUSES.PENDING,
    PROGRESS_STATUSES.COMPLETED,
    PROGRESS_STATUSES.CANCELLED
];

export const DefaultActiveStatuses = [
    PROGRESS_STATUSES.ON_TIME,
    PROGRESS_STATUSES.DELAYED,
    PROGRESS_STATUSES.PENDING,
    PROGRESS_STATUSES.COMPLETED
];

export const getStatusVariant = (status: string): LabelProps['variant'] => {
    switch (status) {
        case PROGRESS_STATUSES.PENDING:
            return LabelVariants.YELLOW;
        case PROGRESS_STATUSES.DELAYED:
            return LabelVariants.RED;
        case PROGRESS_STATUSES.COMPLETED:
            return LabelVariants.GREEN;
        case PROGRESS_STATUSES.ON_TIME:
            return LabelVariants.GREEN;
        case PROGRESS_STATUSES.TOTAL:
            return LabelVariants.BLUE;
        case PROGRESS_STATUSES.CANCELLED:
        default:
            return LabelVariants.NEUTRAL;
    }
};

export const mapStatusTotalsToStatuses = (status?: string) => {
    switch (status) {
        case ORDER_STATUS_FROM_RESPONSE.ON_TIME:
            return PROGRESS_STATUSES.ON_TIME;
        case ORDER_STATUS_FROM_RESPONSE.CANCELLED:
            return PROGRESS_STATUSES.CANCELLED;
        case ORDER_STATUS_FROM_RESPONSE.COMPLETED:
            return PROGRESS_STATUSES.COMPLETED;
        case ORDER_STATUS_FROM_RESPONSE.DELAYED:
            return PROGRESS_STATUSES.DELAYED;
        case ORDER_STATUS_FROM_RESPONSE.PENDING:
            return PROGRESS_STATUSES.PENDING;
        case ORDER_STATUS_FROM_RESPONSE.TOTAL:
        default:
            return PROGRESS_STATUSES.TOTAL;
    }
};

export const mapMirTotalsToStatuses = (status?: string) => {
    switch (status) {
        case MIR_STATUSES_FOR_RESPONSE.ALL:
            return MIR_STATUSES.ALL;
        case MIR_STATUSES_FOR_RESPONSE.CLOSED:
            return MIR_STATUSES.CLOSED;
        case MIR_STATUSES_FOR_RESPONSE.IN_CHOICE_REVIEW:
            return MIR_STATUSES.IN_CHOICE_REVIEW;
        case MIR_STATUSES_FOR_RESPONSE.NEW_RECORD:
            return MIR_STATUSES.NEW_RECORD;
        case MIR_STATUSES_FOR_RESPONSE.IN_CLIENT_REVIEW:
            return MIR_STATUSES.IN_CLIENT_REVIEW;
        default:
            return MIR_STATUSES.ALL;
    }
};

export const getFormattedStatuses = (
    prevSelectedStatuses?: string[],
    newStatus?: string
): GetFormattedStatusesReturn => {
    if (!prevSelectedStatuses || !newStatus)
        return { newStatus: '', selectedStatuses: [] };

    const filteredPrevStatuses = prevSelectedStatuses?.filter(
        (item) => item !== 'null'
    );
    const selectedStatuses = new Set(
        filteredPrevStatuses
            ? [...filteredPrevStatuses, newStatus]
            : [newStatus]
    );

    const alreadyHaveTotal = filteredPrevStatuses?.includes(
        PROGRESS_STATUSES.TOTAL
    );
    const isTotal = newStatus === PROGRESS_STATUSES.TOTAL;

    if (filteredPrevStatuses?.find((item) => item === newStatus)) {
        selectedStatuses.delete(newStatus);
    }

    const statusesForAll = FullStatusList.every((item) =>
        selectedStatuses.has(item)
    );

    if (statusesForAll) {
        Object.values(PROGRESS_STATUSES).forEach((item) =>
            selectedStatuses.add(item)
        );
    }

    if (!alreadyHaveTotal && isTotal) {
        selectedStatuses.clear();
        Object.values(PROGRESS_STATUSES).forEach((item) =>
            selectedStatuses.add(item)
        );
    }

    if (alreadyHaveTotal && !isTotal) {
        selectedStatuses.delete(PROGRESS_STATUSES.TOTAL);
    }

    if (alreadyHaveTotal && isTotal) {
        selectedStatuses.clear();
    }

    const formattedSelectedStatus = Array.from(selectedStatuses);

    return {
        newStatus: formattedSelectedStatus.join('|'),
        selectedStatuses: formattedSelectedStatus
    };
};
