import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { notificationsApi } from '@apis/notifications/Notifications.api';
import {
    NotificationsResponse,
    NotificationType
} from '@customTypes/notifications/notifications.type';
import { Action } from '@store/index';

export type NotificationData = NotificationType[][];

export enum ORDER_CONFIRMATION_STATUS {
    PENDING = 'Pending',
    CONFIRMED = 'Confirmed',
    REJECTED = 'Reject'
}

interface InitialState {
    data: NotificationData;
    newData: NotificationData;
    totalCount: number;
    loading: boolean;
}

const initialState: InitialState = {
    data: [],
    newData: [],
    totalCount: 0,
    loading: false
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        resetNotificationsState(state) {
            state.totalCount = initialState.totalCount;
            state.data = initialState.data;
            state.newData = initialState.newData;
            state.loading = initialState.loading;
        },
        addNewNotification(state, action: Action<NotificationType>) {
            state.newData = [[action.payload], ...state.newData];
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            notificationsApi.endpoints.getNotifications.matchFulfilled,
            (state, { payload }: PayloadAction<NotificationsResponse>) => {
                state.data = [
                    ...state.data,
                    ...(payload.data
                        ? payload.data.items.map((item) => [item])
                        : [])
                ];
                state.totalCount = payload.data?.totalItemsCount || 0;
                state.loading = false;
            }
        );
        builder.addMatcher(
            notificationsApi.endpoints.getNotifications.matchPending,
            (state) => {
                state.loading = true;
            }
        );
        builder.addMatcher(
            notificationsApi.endpoints.getNotifications.matchRejected,
            (state) => {
                state.loading = false;
            }
        );
    }
});

export const { resetNotificationsState, addNewNotification } =
    notificationsSlice.actions;
