import {
    getDefaultPostalCodeValidationRules,
    getDefaultValidationRules
} from '@helpers/getDefaultValidationRules';

export const DefaultValidationRules = {
    postalCodeRequired: (
        backEndValidationRegExPattern: string | null,
        value?: string
    ) =>
        getDefaultPostalCodeValidationRules(
            backEndValidationRegExPattern,
            value
        ),

    textInputRequired: (fieldName: string, isRequired?: boolean) =>
        getDefaultValidationRules(fieldName, isRequired)
};
