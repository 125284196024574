import React from 'react';

import { useLocation, useNavigate, Location } from 'react-router-dom';

import { Button } from '@atoms/button/Button';
import { EmptyState } from '@atoms/emptyState/EmptyState';
import { RoutesList } from '@constants/routesList';
import { SvgSideChoiceLogo } from '@icons/SideChoiceLogo';

import styles from './NotAllowedTemplate.module.less';

interface LocationState extends Location {
    state: { from: string };
}

export enum ERROR_PAGE_TYPE {
    NOT_ALLOWED = 'NotAllowed',
    NOT_FOUND = 'NotFound'
}

export const NotAllowedTemplate = ({
    type = ERROR_PAGE_TYPE.NOT_ALLOWED
}: {
    type?: ERROR_PAGE_TYPE;
}) => {
    const { state } = useLocation() as LocationState;
    const navigate = useNavigate();
    const onHomeClick = () => navigate(`/${RoutesList.HOME.ROOT}`);

    const getTitleSubtitle = (pageType: ERROR_PAGE_TYPE) => {
        switch (pageType) {
            case ERROR_PAGE_TYPE.NOT_ALLOWED:
                return {
                    title: `Access to ${
                        state?.from || 'this page'
                    } is not allowed for this profile`,
                    subtitle: 'Contact your Admin for further assistance'
                };
            case ERROR_PAGE_TYPE.NOT_FOUND:
            default:
                return {
                    title: '404 Page Not Found',
                    subtitle:
                        "Page you're looking for doesn't exist or another error occurred"
                };
        }
    };

    const { title, subtitle } = getTitleSubtitle(type);

    return (
        <div className={styles.Container} id="not-allowed-page">
            <EmptyState title={title} subtitle={subtitle}>
                <Button
                    text="Home"
                    className={styles.Container__HomeButton}
                    startIcon={<SvgSideChoiceLogo />}
                    theme="primary"
                    onClick={onHomeClick}
                />
            </EmptyState>
        </div>
    );
};
