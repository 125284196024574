import { Rule } from 'antd/lib/form';

import { SerialNamePath } from 'components/UI/organisms/serialsAndReferences/modals/enterSerial/types';

export interface FormField {
    title?: string;
    fieldName: string | SerialNamePath;
    titleSize?: number;
    titleWeight?: number;
    rules?: Rule[];
    initialValue?: string | number | null;
    validateTrigger?: Array<TriggerEventType>;
    wrapperClassName?: string;
}

export enum ValidationTriggerEvents {
    ON_CHANGE = 'onChange',
    ON_BLUR = 'onBlur'
}

export const defaultValidateTrigger = [
    ValidationTriggerEvents.ON_BLUR,
    ValidationTriggerEvents.ON_CHANGE
];

export type TriggerEventType =
    | ValidationTriggerEvents.ON_CHANGE
    | ValidationTriggerEvents.ON_BLUR;
