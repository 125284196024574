import { lazy } from 'react';

const ProfilesPage = lazy(
    () => import('components/pages/adminPanel/profiles/Profiles.page')
);
const ProfilesCreatePage = lazy(
    () => import('components/pages/adminPanel/profiles/ProfilesCreate.page')
);

const OrderDetailsPage = lazy(
    () => import('components/pages/trackOrders/orderDetails/OrderDetails.page')
);

const DeliveryUploadPage = lazy(
    () => import('@pages/orders/deliveryUpload/DeliveryUpload.page')
);

const CustomerOrderPage = lazy(
    () => import('components/pages/orders/customerOrder/CustomerOrder.page')
);

const FromChoiceOrderPage = lazy(
    () => import('@pages/orders/fromChoice/FromChoiceOrder.page')
);

const ChoiceAdminPage = lazy(
    () => import('components/pages/adminPanel/choiceAdmin/ChoiceAdmin.page')
);

const ChoiceAdminCreatePage = lazy(
    () =>
        import('components/pages/adminPanel/choiceAdmin/ChoiceAdminCreate.page')
);

const AdminsPage = lazy(
    () => import('components/pages/adminPanel/admins/Admins.page')
);

const AdminCreatePage = lazy(
    () => import('components/pages/adminPanel/admins/AdminCreatePage')
);

const AdminUsersPage = lazy(
    () => import('components/pages/adminPanel/users/AdminUsers.page')
);

const AdminUserCreatePage = lazy(
    () => import('components/pages/adminPanel/users/AdminUserCreatePage')
);

const MirDetailsPage = lazy(
    () =>
        import(
            '@pages/manageInventory/materialInReview/mirDetails/MirDetails.page'
        )
);

const ReplenishmentUploadPage = lazy(
    () => import('@pages/orders/replenishmentUpload/ReplenishmentUpload.page')
);

const TransfersUploadPage = lazy(
    () => import('@pages/orders/transfersUpload/TransfersUpload.page')
);

const MaterialInReviewPage = lazy(
    () =>
        import('@pages/manageInventory/materialInReview/MaterialInReview.page')
);

const ReplenishmentOrderPage = lazy(
    () => import('@pages/orders/replenishmentOrder/ReplenishmentOrder.page')
);

const GoodToChoicePage = lazy(
    () => import('@pages/orders/goodToChoice/GoodToChoice.page')
);

const TransferOrderPage = lazy(
    () => import('@pages/orders/transferOrder/TransferOrder.page')
);

export {
    CustomerOrderPage,
    FromChoiceOrderPage,
    ChoiceAdminPage,
    ChoiceAdminCreatePage,
    AdminsPage,
    AdminCreatePage,
    AdminUsersPage,
    AdminUserCreatePage,
    DeliveryUploadPage,
    OrderDetailsPage,
    ProfilesPage,
    ProfilesCreatePage,
    MirDetailsPage,
    TransfersUploadPage,
    ReplenishmentUploadPage,
    MaterialInReviewPage,
    ReplenishmentOrderPage,
    TransferOrderPage,
    GoodToChoicePage
};
