import axios, { AxiosError, AxiosResponse } from 'axios';

import {
    ChangePasswordDTO,
    RefreshTokenDTO,
    ReturnDataFormatter,
    ReturnDataFormatterResponse,
    SignInDTO,
    SignInResponse,
    VerificationData,
    VerifyCodeDTO,
    VerifyEmailDTO
} from '@apis/auth/authApi.types';
import { getAuthPath } from '@apis/auth/authUtils';
import { AppController } from '@configs/AppController';
import { RoutesList } from '@constants/routesList';
import {
    HTTP_METHODS,
    RequestStatusWithData,
    STATUS_CODES
} from '@customTypes/api/api.types';
import {
    getLocalStorageItem,
    LOCAL_STORAGE_FIELDS,
    setLocalStorageItem
} from '@helpers/localStorage';

export default class AuthApi {
    static async signIn(dto: SignInDTO) {
        const appConfig = AppController.getInstance().config;
        const authPathMainPart = appConfig?.b2c.authPath;

        if (authPathMainPart) {
            const params = new URLSearchParams();
            this.generateURLData(dto, params);

            const authPath = getAuthPath(authPathMainPart);

            return axios.post<SignInDTO, RequestStatusWithData<SignInResponse>>(
                authPath,
                params
            );
        }
    }

    static async refreshToken(dto: RefreshTokenDTO) {
        const appConfig = AppController.getInstance().config;
        const authPathMainPart = appConfig?.b2c.authPath;

        if (authPathMainPart) {
            const params = new URLSearchParams();
            this.generateURLData(dto, params);

            const authPath = getAuthPath(authPathMainPart);

            const response = await axios.post<
                RefreshTokenDTO,
                AxiosResponse<SignInResponse>
            >(authPath, params);

            if (response?.data?.access_token && response?.data?.refresh_token) {
                const newAcToken = response.data.access_token;
                const newRefreshToken = response.data.refresh_token;

                this.setTokens(newAcToken, newRefreshToken);
            }

            return response;
        }
        return null;
    }

    static verifyEmail(dto: VerifyEmailDTO) {
        return this.makeRequest<
            VerifyEmailDTO,
            Pick<VerificationData, 'emailConfirmationId'>
        >('Credentials/SendEmailConfirmationCode', HTTP_METHODS.POST, dto);
    }

    static verifyCode(dto: VerifyCodeDTO) {
        return this.makeRequest<VerifyCodeDTO, Pick<VerificationData, 'token'>>(
            'Credentials/ValidateEmailConfirmationCode',
            HTTP_METHODS.POST,
            dto
        );
    }

    static changePassword(dto: ChangePasswordDTO) {
        return this.makeRequest<ChangePasswordDTO, null>(
            'Credentials/ChangePassword',
            HTTP_METHODS.POST,
            dto
        );
    }

    private static async makeRequest<DtoType, ResponseType>(
        path: string,
        method: HTTP_METHODS,
        dto: DtoType
    ) {
        try {
            const response = await this.sendAuthApiRequest<
                DtoType,
                RequestStatusWithData<ResponseType>
            >(path, method, dto);

            return this.authResponseFormatter({
                data: response.data,
                responseCode: response.data.statusCode
            });
        } catch (axiosError) {
            const error = axiosError as AxiosError;

            return this.authResponseFormatter({
                data: null,
                responseCode: error?.response?.status
            });
        }
    }

    static signOut() {
        localStorage.clear();
        window.location.assign(`/${RoutesList.AUTH.ROOT}`);
    }

    static setTokens(acToken: string, refreshToken: string) {
        setLocalStorageItem(LOCAL_STORAGE_FIELDS.ACCESS_TOKEN, acToken);
        setLocalStorageItem(LOCAL_STORAGE_FIELDS.REFRESH_TOKEN, refreshToken);
    }

    static getAccessToken(): string {
        const token = getLocalStorageItem(LOCAL_STORAGE_FIELDS.ACCESS_TOKEN);
        return token || '';
    }

    static getRefreshToken(): string {
        const refreshToken = getLocalStorageItem(
            LOCAL_STORAGE_FIELDS.REFRESH_TOKEN
        );
        return refreshToken || '';
    }

    private static sendAuthApiRequest<DtoType, ReturnType>(
        path: string,
        method: HTTP_METHODS,
        dto?: DtoType
    ): Promise<AxiosResponse<ReturnType>> {
        const appConfig = AppController.getInstance().config;

        return axios[method](`${appConfig?.api_host}/api/${path}`, dto, {
            headers: {
                'Api-Version': '3.0'
            }
        });
    }

    private static authResponseFormatter<T>({
        data,
        responseCode
    }: ReturnDataFormatter<T>): ReturnDataFormatterResponse<T> {
        const errorCodes = [
            STATUS_CODES.BASE_ERROR,
            STATUS_CODES.DATA_NOT_AVAILABLE,
            STATUS_CODES.HTTP_ERROR,
            STATUS_CODES.INTERNAL_ERROR,
            STATUS_CODES.AUTH_EXPIRED
        ];

        return {
            response: data,
            isError: errorCodes.some((item) => item === responseCode),
            isExpired: responseCode === STATUS_CODES.AUTH_EXPIRED
        };
    }

    private static generateURLData<T>(data: T, url: URLSearchParams) {
        const params = Object.entries(data);

        params.forEach((item) => {
            url.append(item[0], item[1]);
        });
    }
}
