import { TransformedQuickAccessResponse } from '@apis/users/UsersApi.types';

export const getSelectedPermissions = (
    allPermissions: TransformedQuickAccessResponse[]
): TransformedQuickAccessResponse[] => {
    return allPermissions
        .map((item) => {
            return {
                ...item,
                permissions: item.permissions.filter((el) => el.isSelected)
            };
        })
        .filter((item) => item.permissions.length);
};
