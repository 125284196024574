import React, { useState } from 'react';

import { DATE_FORMAT } from '@apis/users/UsersApi.types';
import { Button } from '@atoms/button/Button';
import {
    GoToOrderDto,
    NotificationType
} from '@customTypes/notifications/notifications.type';
import { Card } from '@organisms/notificationsPanel/shared/card/Card';
import { getFormattedReferenceNumbers } from '@organisms/notificationsPanel/shared/notificationsUtils';
import { SvgExpandUp } from 'components/icons';

import styles from './CardContainer.module.less';

export interface Props {
    data: NotificationType[];
    dateFormatKey?: DATE_FORMAT;
    goToDetailsPage: (data: GoToOrderDto) => void;
}

export const CardContainer = ({
    data,
    goToDetailsPage,
    dateFormatKey
}: Props) => {
    const [showStacked, setShowStacked] = useState(false);
    const recentItem = data[0];
    const isSingle = data.length === 1;
    const showMoreBtnHandler =
        isSingle || showStacked ? undefined : () => setShowStacked(true);
    const isStackedCard = !isSingle && showStacked;

    return (
        <div
            className={styles.CardContainer}
            data-testid="NotificationCardContainer"
            id={String(recentItem.userNotificationId)}
        >
            {showStacked && (
                <div className={styles.ShowLessBtnContainer}>
                    <Button
                        testId="ShowLessButton"
                        theme="link"
                        size="small"
                        text="Show less"
                        endIcon={<SvgExpandUp />}
                        onClick={() => setShowStacked(false)}
                    />
                </div>
            )}
            <Card
                id={recentItem.userNotificationId}
                orderId={recentItem.order.orderId}
                status={recentItem.order.status}
                isRead={recentItem.isRead}
                account={recentItem.order.accountNum}
                subAccount={recentItem.order.subAccountDescription}
                referenceNumbers={getFormattedReferenceNumbers(
                    recentItem.order
                )}
                proNumber={recentItem.order.proNumber}
                proNumberAsLink={Boolean(recentItem.order.proNumber?.length)}
                goToDetailsPage={goToDetailsPage}
                rejectionReasons={recentItem.order.rejectionReasons}
                onShowMoreBtnClick={showMoreBtnHandler}
                createdDate={recentItem.createdDate}
                dateFormatKey={dateFormatKey}
                type={recentItem.order.type}
                totalCount={data.length}
            />
            {isStackedCard &&
                data
                    .slice(1)
                    .map((item) => (
                        <Card
                            key={item.userNotificationId}
                            id={item.userNotificationId}
                            orderId={item.order.orderId}
                            status={item.order.status}
                            isRead={item.isRead}
                            account={item.order.accountNum}
                            subAccount={item.order.subAccountNum}
                            referenceNumbers={getFormattedReferenceNumbers(
                                item.order
                            )}
                            proNumber={item.order.proNumber}
                            proNumberAsLink={Boolean(
                                item.order.proNumber?.length
                            )}
                            goToDetailsPage={goToDetailsPage}
                            rejectionReasons={item.order.rejectionReasons}
                            createdDate={item.createdDate}
                            dateFormatKey={dateFormatKey}
                            type={item.order.type}
                        />
                    ))}
        </div>
    );
};
