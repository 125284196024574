import * as React from 'react';
import type { SVGProps } from 'react';
export const SvgNewNotification = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M8 12.667V3.334m0 0L3.333 8.001M8 3.334l4.666 4.667"
        />
    </svg>
);
