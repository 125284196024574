import React from 'react';

import { useGetClosestWarehousesMutation } from '@apis/manageInventory/GetClosestWarehouses.api';
import { EmptyState } from '@atoms/emptyState/EmptyState';
import { STATUS_CODES } from '@customTypes/api/api.types';
import { PanelBlock } from '@molecules/panelBlock/PanelBlock';
import { LocateClosestWarehouse } from '@organisms/manageInventory/locateClosestWarehouse/LocateClosestWarehouse';

import styles from './LocateClosestWarehouse.module.less';

export const LocateClosestTemplate = () => {
    const [getData, { data, isLoading, reset }] =
        useGetClosestWarehousesMutation();

    const statusCode = data?.statusCode;

    const showErrorScreen =
        statusCode &&
        statusCode !== STATUS_CODES.INVALID_DESTINATION &&
        statusCode !== STATUS_CODES.FILTERED_DATA_NOT_FOUND;

    return (
        <>
            {showErrorScreen ? (
                <EmptyState
                    title="Unexpected error occurred"
                    className={styles.Error__Block}
                />
            ) : (
                <PanelBlock
                    title="Locate Closest Warehouse"
                    hideHeaderOnMobile={false}
                    withDivider={true}
                    fullWidth={false}
                    fullHeight={false}
                    isFullWidthOnSmallDesktop={true}
                >
                    <LocateClosestWarehouse
                        getData={getData}
                        isLoading={isLoading}
                        closestWarehouseData={data}
                        reset={reset}
                    />
                </PanelBlock>
            )}
        </>
    );
};
