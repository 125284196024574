import {
    GetPermissionsResponse,
    Permission
} from '@apis/permissions/Permissions.type';
import {
    BasicPaginationResponse,
    RequestStatusWithData,
    RequestWithPagination
} from '@customTypes/api/api.types';
import { BaseWarehouse } from '@customTypes/general/warehouse.type';
import { ChoiceAdmin } from '@customTypes/users/admins.types';
import { USERS } from '@customTypes/users/permissions.types';
import { AdminUser, BasicUser } from '@customTypes/users/users.types';

export enum REQUEST_PATH {
    ACCOUNTS_AND_SUB_ACCOUNTS = '/Users/GetAccountsAndSubAccounts/:onlyTransactional',
    CREATE_CHOICE_ADMIN = '/Users/CreateChoiceAdmin',
    EDIT_CHOICE_ADMIN = '/Users/EditChoiceAdmin',
    CREATE_ADMIN = '/Users/CreateAdmin',
    EDIT_ADMIN = '/Users/EditAdmin',
    CREATE_USER = '/Users/CreateUser',
    EDIT_USER = '/Users/EditUser',
    CHOICE_ADMINS = '/Users/GetChoiceAdmins',
    UPDATE_LAST_LOGIN = '/Users/UpdateLastLoginDate',
    GET_USERS = '/Users/GetUsers',
    GET_USER_INFO = '/Users/:id',
    GET_USERS_CLIENTS = '/Users/GetUsersClients/:showOnlyActive',
    GET_USER_SETTINGS = '/Users/GetUserSettings',
    UPDATE_USER_SETTINGS = '/Users/UpdateUserSettings',
    GET_ADMIN = '/Users/GetAdmins',
    GET_ADMINS_CLIENT = '/Users/GetAdminsClients/:status',
    DEACTIVATE_USER = '/Users/DeactivateUser/:id',
    ACTIVATE_USER = '/Users/ActivateUser/:id',
    DELETE_USER = '/Users/DeleteUser/:id',
    GET_PERMISSIONS = '/Users/GetPermissions',
    GET_AVAILABLE_WAREHOUSES = '/Users/GetAvailableSsls/:profileId',
    GET_AVAILABLE_TRANSPORT_SERVICES = '/Users/GetAvailableTransportServices',
    GET_HOME_QUICK_ACCESS = '/Users/GetUserHomeQuickAccessLinks',
    UPDATE_HOME_QUICK_ACCESS = '/Users/UpdateUserHomeQuickAccessLinks'
}

export interface SubAccount {
    subAccountId: number;
    subAccountNumber: string;
    accountId: number;
    description: string;
    isExpandedRef: boolean;
    isMandatoryRef2: boolean;
    itemRef: boolean;
    irpItemRef?: boolean;
    isTransactional?: boolean;
}

export interface Admin {
    id: string;
    mail: string;
    displayName: string;
    givenName: string;
    surName: string;
    lastModified: string;
    lastModifiedBy: string;
    status: boolean;
    clientId: number;
    clientName: string;
    lastLogin: string;
}

export interface Account {
    accountId: number;
    accountNumber: string;
    name: string;
    subAccounts: SubAccount[];
}

export interface CreateBasicUserDTO {
    firstName: string;
    lastName: string;
    email: string;
    clientId?: number;
}

export interface EditBasicUserDTO {
    firstName: string;
    lastName: string;
    userId: string;
}

export interface EditUserDTO extends EditBasicUserDTO {
    profileId: number;
    ssls?: { sslId: number }[];
    transportServices?: TransportServices;
}

export interface CreateUserForAdminDTO extends CreateBasicUserDTO {
    profileId: number;
}

export type TransportServices = {
    transportServiceMappings: { transportServiceMappingId: number }[];
    carrierServices: { transportServiceId: number }[];
};

export interface CreateUserDTO extends CreateUserForAdminDTO {
    clientId?: number;
    ssls?: { sslId: number }[];
    transportServices?: TransportServices;
}

interface GetAdminPanelUsers extends RequestWithPagination {
    cacheId?: string;
}

export interface GetChoiceAdminDTO extends GetAdminPanelUsers {
    fixedSorter?: 'surName' | '-surName' | 'lastModified';
    additionalFixedSorter?: AdditionalSortsColumns;
}

export interface GetUsersDTO extends GetAdminPanelUsers {
    fixedSorter?: FixedSorter;
    additionalFixedSorter?: AdditionalSortsColumns;
}

export type AdminPanelResponse = RequestStatusWithData<{
    warningCodes: number[];
}>;

export interface GetChoiceAdminsTransformedResponse
    extends GetChoiceAdminsResponse {
    cacheId?: string;
}

export type UserWarehouseItem = Pick<BaseWarehouse, 'code' | 'city'> & {
    id: number;
    country: string;
};

export interface UserWarehousesDataItem {
    region: string;
    availableCountries: {
        country: string;
        sslList: UserWarehouseItem[];
    }[];
}

export enum DATE_FORMAT {
    MONTH_DAY_YEAR = 'MM/DD/YYYY',
    DAY_MONTH_YEAR = 'DD.MM.YYYY'
}

export enum TIME_FORMAT {
    '12_HOURS' = '12',
    '24_HOURS' = '24'
}

export enum MEASUREMENTS {
    METRIC = 'Metric',
    US = 'US'
}

export interface UserSettings {
    dateFormatKey: DATE_FORMAT;
    timeFormatKey: TIME_FORMAT;
    measurementKey: MEASUREMENTS;
    isDarkModeEnabled: boolean;
    isQuickAccessEnabled: boolean;
    isOrderWatchlistEnabled: boolean;
}

export interface UserInfoTransportServices {
    transportServiceMappings: {
        id: number;
        name: string;
        levelId: string;
    }[];
    carrierServices: {
        id: number;
        name: string;
    }[];
}

export interface UserInfo extends BasicUser {
    profileName: string;
    clientName: string;
    userId: string;
    userType: USERS;
    transportServices: UserInfoTransportServices;
    ssls: {
        sslId: number;
        sslCode: string;
    }[];
}

export type AccountsSubAccounts = RequestStatusWithData<Account[]>;

export type AdditionalFixedSorter =
    | 'surName'
    | '-surName'
    | 'givenName'
    | '-givenName';
export type FixedSorter = 'lastModified' | AdditionalFixedSorter;
export type AdditionalSortsColumns =
    | AdditionalFixedSorter
    | AdditionalFixedSorter[];

export type GetUserByRoleResponse<T> = RequestStatusWithData<
    BasicPaginationResponse & {
        items: T[];
        hasInactiveUsers: boolean;
    }
>;

interface QuickAccessPermission extends Permission {
    isSelected: boolean;
}

interface QuickAccessPermissionData extends GetPermissionsResponse {
    permissions: QuickAccessPermission[];
}

export interface TransformedQuickAccessResponse {
    title: string;
    groupKeys: string[];
    permissions: QuickAccessPermission[];
}

export interface UpdateQuickAccessDTO {
    links: number[];
}

export type GetChoiceAdminsResponse = GetUserByRoleResponse<ChoiceAdmin>;
export type GetAdminsResponse = GetUserByRoleResponse<Admin>;
export type GetUsersResponse = GetUserByRoleResponse<AdminUser>;
export type GetUserInfoResponse = RequestStatusWithData<UserInfo>;
export type GetAvailableWarehousesResponse = RequestStatusWithData<{
    availableSslList: UserWarehousesDataItem[];
}>;

export type GetAvailableTransportServicesResponse = RequestStatusWithData<
    {
        level1Id: number;
        level1Description: string;
        level2Item: {
            level2Id: number;
            level2Description: string;
            transportServiceMappingId: number;
            level3Item:
                | {
                      level3Id: number;
                      level3ItemDescription: string;
                  }[]
                | null;
        }[];
    }[]
>;
export type GetUserSettingsResponse = RequestStatusWithData<UserSettings>;
export type GetQuickAccessResponse =
    RequestStatusWithData<QuickAccessPermissionData>;
export type GetTransformedQuickAccessResponse = RequestStatusWithData<
    TransformedQuickAccessResponse[]
>;
