import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { Client } from '@apis/clients/ClientsApi.types';
import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import {
    GetTransformedProfileByIdResponse,
    EditProfileDTO,
    GetProfileByIdResponse,
    GetProfilesResponse,
    GetProfilesTransformedResponse,
    SaveProfileDTO,
    REQUEST_PATH
} from '@apis/profiles/Profiles.type';
import { getProfilesApiByRole } from '@apis/profiles/ProfilesApiByRole';
import {
    RequestStatusWithData,
    RequestStatusWithEmptyData,
    RequestWithPagination
} from '@customTypes/api/api.types';
import { getBasePaginationBody } from '@helpers/getBasePaginationBody';
import { permissionsDataTransformer } from '@helpers/profilesHelpers';

export const profilesApi = createApi({
    reducerPath: 'profilesApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getProfile: builder.mutation<
            GetProfilesTransformedResponse,
            RequestWithPagination
        >({
            query: (dto) => ({
                url: REQUEST_PATH.GET_LIST,
                method: 'POST',
                body: getBasePaginationBody({
                    ...dto,
                    filterFields: ['DisplayName'],
                    withoutBaseQueries: true,
                    fixedSorter: 'displayName'
                })
            }),
            transformResponse(
                baseResponse: GetProfilesResponse
            ): GetProfilesTransformedResponse {
                return {
                    ...baseResponse,
                    items: baseResponse.data?.items.map((item) => ({
                        ...item,
                        id: String(item.profileId)
                    }))
                };
            }
        }),

        getProfilesClients: builder.query<
            RequestStatusWithData<Client[]>,
            void
        >({
            query: () => ({ url: REQUEST_PATH.GET_PROFILES_CLIENTS })
        }),

        getProfileById: builder.query<
            GetTransformedProfileByIdResponse,
            string
        >({
            query: (id) => ({
                url: REQUEST_PATH.GET_PROFILE_BY_ID.replace(':id', id)
            }),
            transformResponse: (
                response: GetProfileByIdResponse
            ): GetTransformedProfileByIdResponse => {
                return {
                    ...response,
                    data: {
                        ...response.data,
                        permissionsAndGroups: permissionsDataTransformer(
                            response.data.permissionsAndGroups.groups,
                            response.data.permissionsAndGroups.permissions
                        )
                    }
                };
            }
        }),

        saveProfile: builder.mutation<
            RequestStatusWithEmptyData,
            SaveProfileDTO
        >({
            query: (dto) => ({
                url: REQUEST_PATH.SAVE_PROFILE,
                method: 'POST',
                body: dto
            })
        }),

        editProfile: builder.mutation<
            RequestStatusWithEmptyData,
            EditProfileDTO
        >({
            query: (dto) => ({
                url: REQUEST_PATH.SAVE_PROFILE,
                method: 'PUT',
                body: dto
            })
        }),
        deleteProfile: builder.mutation<RequestStatusWithEmptyData, string>({
            query: (id) => ({
                url: REQUEST_PATH.DELETE_PROFILE.replace(':id', id),
                method: 'DELETE'
            })
        })
    })
});

export const getProfilesApi = (isChoiceAdmin: boolean) =>
    getProfilesApiByRole(isChoiceAdmin);
