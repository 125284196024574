import {
    getLocalStorageItem,
    removeLocalStorageItem,
    setLocalStorageItem
} from '@helpers/localStorage';

const updateLastLoginKey = 'updateLastLogin';
export const setUpdateLastLogin = () =>
    setLocalStorageItem(updateLastLoginKey, 'true');

export const getUpdateLastLogin = () => getLocalStorageItem(updateLastLoginKey);

export const removeUpdateLastLogin = () =>
    removeLocalStorageItem(updateLastLoginKey);
