export interface Scopes {
    login: string;
}

export interface GetConfig {
    scopes: Scopes;
    clientId: string;
    authPath: string;
}

export enum GRAND_TYPES {
    PASSWORD = 'password'
}

export enum RESPONSE_TYPES {
    TOKEN = 'token',
    ID_TOKEN = 'id_token'
}

export enum SCOPES {
    OPEN_ID = 'openid',
    OFFLINE = 'offline_access'
}
