import React from 'react';

import { pageHeadersList } from '@constants/pageTitles';
import { usePageTitle } from '@helpers/usePageTitle';
import { UserSettingsTemplate } from '@templates/userSettings/UserSettings.template';

export const UserSettingsPage = () => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.USER_SETTINGS);

    return <UserSettingsTemplate />;
};
