export interface CountryState {
    stateId: number;
    provinceCode: string;
    displayName: string;
}

export enum REQUESTS_PATH {
    COUNTRIES = '/Countries',
    GET_STATES = '/States'
}
