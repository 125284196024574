import { useGetServicesMutation as useGetServicesMutationInboundReturn } from 'APIServices/inboundReturn/InboundReturn.api';
import { useGetServicesMutation as useGetServicesMutationOutboundOrderByPart } from 'APIServices/outboundOrderByPart/OutboundOrderByPart.api';
import {
    AccountSubAccount,
    RequestStatusWithData
} from 'utils/types/api/api.types';
import { AvailableServicesDataType as IBulkAvailableServices } from 'utils/types/bulkOrder/bulk.types';

export enum DELIVERY_DATA {
    ImmediateCourierServices = 'Courier',
    AvailableServices = 'Available Services',
    NextFlightServices = 'Next Flight Out',
    ScheduledCourierServices = 'Courier',
    CustomerServices = 'Carrier - Arranged by Recipient',
    CarrierServices = 'Carrier'
}

export enum SERVICE_TYPES {
    ImmediateCourierServices = 'ImmediateCourierServices',
    AvailableServices = 'AvailableServices',
    NextFlightServices = 'NextFlightServices',
    ScheduledCourierServices = 'ScheduledCourierServices',
    CustomerServices = 'CustomerServices',
    CarrierServices = 'CarrierServices'
}

export enum DELIVERY_TRANSPORT_TYPES {
    Immediate = 'Immediate',
    Carrier = 'Scheduled',
    WillCall = 'WillCall'
}

export enum DELIVERY_UI_NAMES {
    Immediate = 'Immediate Delivery',
    Scheduled = 'Scheduled Delivery',
    WillCall = 'Customer/Technician Pick Up'
}

export type Services = {
    [key in 'availableServices' | 'services']: Service[];
};

export type ServicesKeys = keyof typeof DELIVERY_DATA;

export interface Delivery {
    key: DELIVERY_TRANSPORT_TYPES;
    data: AvailableServices;
}

export enum DELIVERY_FORM_FIELDS {
    CITY = 'city',
    STATE = 'state',
    COUNTRY = 'country',
    POSTAL_CODE = 'postalCode'
}

export interface DeliveryCommon {
    key: DELIVERY_TRANSPORT_TYPES;
}

export type DeliveryType =
    | (DeliveryCommon & { data: AvailableServices })
    | (DeliveryCommon & { data: IBulkAvailableServices });

export interface BaseService {
    id: string;
    displayName: string;
    serviceLevel: string;
    blockType?: string;
    isAvailable?: boolean;
    $type?: SERVICE_TYPES;
}

export interface PrepService extends BaseService {
    prepAndTransitMinutes?: number;
}

export interface OnDemandService extends PrepService {
    eta?: string;
}

export type NextFlight = BaseService;

export interface WillCallScheduledService extends PrepService {
    earliestReady?: string;
}

export type ByDriveServices = OnDemandService &
    WillCallScheduledService &
    NextFlight;

export type ByRecipientService = BaseService;

export interface ByChoiceService extends BaseService {
    cutoffTime?: string;
    eta?: string;
    carrierCode?: string;
    carrierName?: string;
}

export type ByCarrierServices = ByRecipientService & ByChoiceService;

export type TechnicianServices = WillCallScheduledService;

export type Service = ByDriveServices & ByCarrierServices & TechnicianServices;

export interface Address {
    countryCode: string;
    postalCode: string | null;
    city: string | null;
    province?: string;
}

interface AvailableServiceDTO {
    transportationType?: string;
    maxIndividualWeight: number;
    totalWeight: number;
    dangerousGoods: {
        class: number;
        un: string;
    }[];
}

export interface AvailableServicesDTO
    extends AccountSubAccount,
        AvailableServiceDTO {
    consigneeAddress?: Address;
    consignorSsl?: string;
}

export interface AvailableConsignorServicesDTO
    extends AccountSubAccount,
        AvailableServiceDTO {
    consignorAddress?: Address;
    consigneeSsl?: string;
}

export type AvailableServicesResponse =
    RequestStatusWithData<AvailableServices>;

export interface AvailableServices {
    localTimeAtConsignorAddress: string;
    localTimeAtConsigneeAddress: string;
    localTimeDisplayTimeZoneAtConsigneeAddress: string;
    localTimeDisplayTimeZoneAtConsignorAddress: string;
    messageTimeStamp: string;
    returnPackAvailability: boolean;
    ticketRequestType: string;
    statusCode?: number;
    statusMessage?: string;
    id: string;

    willCall: Services;
    scheduled: Services;
    immediate: Services;
}

export type UseGetServicesType =
    | typeof useGetServicesMutationOutboundOrderByPart
    | typeof useGetServicesMutationInboundReturn;

type ConfigOmittedField = 'transportationType' | 'accountNum' | 'subAccountNum';

export type InitialConsigneeConfig = Omit<
    AvailableServicesDTO,
    ConfigOmittedField
>;

export type InitialConsignorConfig = Omit<
    AvailableConsignorServicesDTO,
    ConfigOmittedField
>;

export type ActiveKey = string | string[] | undefined;

export type AccordionOptions = {
    childContent: JSX.Element;
    title: DELIVERY_UI_NAMES;
    key: DELIVERY_TRANSPORT_TYPES;
    description: string;
};
