import { AppController } from '@configs/AppController';

interface Actions {
    onLoad: VoidFunction;
    onError: VoidFunction;
}

export const addDundas = ({ onLoad, onError }: Actions) => {
    const staticUrl = AppController.getInstance().config?.static_host;

    if (staticUrl) {
        const script = document.createElement('script');

        script.src = `${staticUrl}/dundas.embed.min.js`;
        script.id = 'dundasBI';
        script.onload = onLoad;
        script.onerror = onError;
        document.body.appendChild(script);
    }
};
