import React from 'react';

import { pageHeadersList } from '@constants/pageTitles';
import { usePageTitle } from '@helpers/usePageTitle';
import {
    ERROR_PAGE_TYPE,
    NotAllowedTemplate
} from '@templates/notAllowed/NotAllowed.template';

export const NotFoundPage = () => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.NOT_FOUND);

    return <NotAllowedTemplate type={ERROR_PAGE_TYPE.NOT_FOUND} />;
};
