import React from 'react';

import classNames from 'classnames';

import styles from './ScreenBlock.module.less';

interface Props {
    children?: React.ReactNode | React.ReactNode[];
    fullHeight?: boolean;
    fullWidth?: boolean;
    withTopMargin?: boolean;
    contentClassName?: string;
}

export const ScreenBlock = ({
    children,
    fullHeight = true,
    fullWidth = true,
    withTopMargin = true,
    contentClassName
}: Props) => {
    return (
        <div
            className={classNames(styles.Block, {
                [styles.Block__Top]: withTopMargin
            })}
        >
            <div className={styles.Wrapper} data-testid="Wrapper">
                <div
                    className={classNames(styles.Wrapper__Container, {
                        [styles.Wrapper__Container__FullWidth]: fullWidth
                    })}
                >
                    <div
                        className={styles.Wrapper__Content}
                        data-testid="Content"
                    >
                        <div
                            className={classNames(
                                styles.Content,
                                {
                                    [styles.Content__Grow]: fullHeight
                                },
                                contentClassName
                            )}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
