export const CancelMessage = 'Are you sure you want to Cancel?';
export const CancelEditMessage = 'All changes done will be lost';
export const CancelCreateMessage = 'All entered data will be lost';
export const CancelDefaultMessage = 'Entered information will be lost';
export const CancelOrderMessage = 'All order data will be lost';

export enum AdminPanelCancelMessages {
    ADMIN = 'Cancel new Admin creation?',
    CHOICE_ADMIN = 'Cancel new Choice Admin creation?',
    PROFILE = 'Cancel new Profile creation?',
    USER = 'Cancel new User creation?'
}

export const DisabledServiceMessage =
    'Your access to transport services is restricted by your administrators. Please contact them for updates.';

export const WelcomeMessage = `Compass provides a single access point to create, manage, monitor 
and analyze your inventory and orders processed by Choice Logistics.`;

export const OrderWatchListMessage = `To add an order to the watch list, go to the "Order Tracking" section, 
select any order, and click the "Add to Watchlist" button. 
You can add any order. To remove an order, uncheck the watchlist flag`;
