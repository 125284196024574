import React, { forwardRef, ReactNode } from 'react';

import { InputRef, Select as AntSelect } from 'antd';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import ReactDatePicker, {
    ReactDatePickerCustomHeaderProps,
    ReactDatePickerProps
} from 'react-datepicker';

import { InputType, TextInput } from '@atoms/input/Input';
import { Select } from '@atoms/select/Select';
import { months } from '@constants/datePicker';
import { NullableDate } from '@customTypes/general/general.types';
import { checkAvailability } from '@helpers/checkAvailability';
import { getDefaultYearsList } from '@helpers/getDefaultYearsList';
import { SvgArrow, SvgCalendar, SvgDoubleArrow } from 'components/icons';

import styles from './DatePicker.module.less';

import 'react-datepicker/dist/react-datepicker.min.css';

const { Option } = AntSelect;

export interface DatePickerProps<T extends boolean | undefined = false>
    extends Omit<ReactDatePickerProps<'WithRange', T>, 'value'> {
    value?: NullableDate;
    years?: number[];
    availableMonths?: string[];
    hasError?: boolean;
    dateTitle?: string;
    customInputStyles?: string;
    headerClassName?: string;
    customInputIcon?: ReactNode;
    customInputPlaceholder?: string;
    formattedDateKey?: string;
}

type CustomInputProps = InputType & {
    customInputIcon?: ReactNode;
    customInputStyles?: string;
    customInputPlaceholder?: string;
};

const CustomInput = forwardRef<InputRef, CustomInputProps>(
    (
        {
            customInputIcon,
            customInputStyles,
            customInputPlaceholder,
            ...props
        },
        ref
    ) => {
        return (
            <div className={styles.DatePicker__Input}>
                <TextInput
                    {...props}
                    placeholder={customInputPlaceholder}
                    suffix={
                        customInputIcon || (
                            <SvgCalendar data-testid="calendar icon" />
                        )
                    }
                    readOnly
                    innerRef={ref}
                    className={customInputStyles}
                />
            </div>
        );
    }
);
CustomInput.displayName = 'CustomInput';

export function DatePicker<T extends boolean | undefined>({
    value,
    years = getDefaultYearsList(),
    hasError = false,
    minDate,
    maxDate,
    dateTitle,
    required = true,
    className,
    customInputStyles,
    customInputIcon,
    customInputPlaceholder = 'MM/DD/YYYY',
    headerClassName,
    formattedDateKey = 'MM/dd/yyyy',
    ...props
}: DatePickerProps<T>) {
    const renderCustomHeader = ({
        changeYear,
        changeMonth,
        customHeaderCount,
        monthDate,
        increaseMonth,
        increaseYear,
        decreaseMonth,
        decreaseYear
    }: ReactDatePickerCustomHeaderProps) => {
        const selectedYear = DateTime.fromJSDate(monthDate).year;
        const selectedMonth = DateTime.fromJSDate(monthDate).month;
        const isYearDisabled =
            minDate?.getFullYear() === selectedYear ||
            maxDate?.getFullYear() === selectedYear;

        const getShowArrows = () => {
            if (!maxDate?.getFullYear() || !minDate?.getMonth()) return;
            const isIncreaseYearArrowAvailable =
                selectedYear < maxDate?.getFullYear();
            const isIncreaseMonthArrowAvailable =
                selectedYear <= maxDate?.getFullYear() ||
                selectedMonth < minDate?.getMonth();

            const isDecreaseYearAvailable =
                selectedYear > minDate?.getFullYear();

            const isDecreaseMonthArrowAvailable =
                selectedYear === minDate?.getFullYear()
                    ? selectedMonth !== minDate?.getMonth()
                    : true;

            return {
                isIncreaseYearArrowAvailable,
                isIncreaseMonthArrowAvailable,
                isDecreaseYearAvailable,
                isDecreaseMonthArrowAvailable
            };
        };

        const isDateTo = customHeaderCount !== 0;
        const isCurrentYear = minDate?.getFullYear() !== selectedYear;

        return (
            <div className={styles.Header}>
                {props.selectsRange && (
                    <div
                        className={classNames(styles.Arrows, {
                            [styles.Arrows__Hidden]: isDateTo
                        })}
                    >
                        {getShowArrows()?.isDecreaseYearAvailable && (
                            <SvgDoubleArrow
                                className={styles.Arrows__Double}
                                onClick={decreaseYear}
                                id="prevYear"
                            />
                        )}
                        {getShowArrows()?.isDecreaseMonthArrowAvailable && (
                            <SvgArrow
                                color="#8e94a9"
                                className={styles.Arrows__Left}
                                onClick={decreaseMonth}
                                id="prevMonth"
                            />
                        )}
                    </div>
                )}
                <div
                    className={classNames(
                        styles.DatePicker__Header,
                        headerClassName
                    )}
                >
                    <Select
                        dropdownMatchSelectWidth={false}
                        value={months[DateTime.fromJSDate(monthDate).month - 1]}
                        onSelect={(newMonth) => {
                            const newMonthNumber = months.indexOf(newMonth);
                            return changeMonth(
                                isDateTo ? newMonthNumber - 1 : newMonthNumber
                            );
                        }}
                        data={months.map((month, index) => {
                            const isMonthAvailable = isCurrentYear
                                ? true
                                : checkAvailability(
                                      index,
                                      minDate?.getMonth(),
                                      maxDate?.getMonth()
                                  );

                            const disabledMonth =
                                isYearDisabled && !isMonthAvailable;

                            return (
                                <Option
                                    value={month}
                                    key={`${month}_${index}`}
                                    disabled={disabledMonth}
                                >
                                    {month}
                                </Option>
                            );
                        })}
                        bordered={false}
                    />
                    <Select
                        dropdownMatchSelectWidth={false}
                        value={DateTime.fromJSDate(monthDate).year}
                        onSelect={(newYear) => changeYear(Number(newYear))}
                        options={years.map((value) => {
                            const minYear = minDate?.getFullYear();
                            const maxYear = maxDate?.getFullYear();
                            const yearLessThanMin = minYear && minYear > value;
                            const yearHigherThanMax =
                                maxYear && maxYear < value;

                            const isYearDisabled = Boolean(
                                yearLessThanMin || yearHigherThanMax
                            );
                            return {
                                value,
                                label: value,
                                disabled: isYearDisabled
                            };
                        })}
                        bordered={false}
                    />
                </div>
                {props.selectsRange && (
                    <div
                        className={classNames(styles.Arrows, {
                            [styles.Arrows__Hidden]: customHeaderCount !== 1
                        })}
                    >
                        {getShowArrows()?.isIncreaseMonthArrowAvailable && (
                            <SvgArrow
                                color="#8e94a9"
                                className={styles.Arrows__Right}
                                onClick={increaseMonth}
                                id="nextMonth"
                            />
                        )}
                        {getShowArrows()?.isIncreaseYearArrowAvailable && (
                            <SvgDoubleArrow
                                onClick={increaseYear}
                                id="nextYear"
                            />
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div
            className={classNames(styles.DatePicker, className)}
            data-testid="date-picker"
        >
            {Boolean(dateTitle) && (
                <div className={styles.DatePicker__Labels}>
                    <div className={styles.DatePicker__Label}>{dateTitle}</div>
                    {!required && (
                        <div className={styles.DatePicker__Optional}>
                            Optional
                        </div>
                    )}
                </div>
            )}
            <ReactDatePicker
                {...props}
                minDate={minDate}
                maxDate={maxDate}
                isClearable={false}
                popperPlacement="bottom-start"
                selected={value}
                dateFormat={formattedDateKey}
                customInput={
                    <CustomInput
                        hasError={hasError}
                        customInputStyles={customInputStyles}
                        customInputIcon={customInputIcon}
                        customInputPlaceholder={customInputPlaceholder}
                    />
                }
                renderCustomHeader={renderCustomHeader}
            />
        </div>
    );
}
