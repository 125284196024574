import * as React from 'react';
import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
    color?: string;
}

export const SvgFullArrow = (props: Props) => (
    <svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            className="Arrow"
            d="M12.833 7H1.167m0 0L7 12.833M1.167 7 7 1.167"
            stroke={props.color || '#fff'}
            strokeWidth={1.667}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
