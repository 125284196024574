import React from 'react';

import classNames from 'classnames';

import styles from './Loader.module.less';

interface Props {
    size?: 'xSmall' | 'small' | 'large' | 'medium' | 'xLarge';
    theme?: 'white' | 'blue';
    className?: string;
    fullHeight?: boolean;
}

export const Loader = ({
    size = 'large',
    theme = 'blue',
    className = '',
    fullHeight = false
}: Props) => {
    const loaderClass = classNames(styles.Loader, {
        [styles.Loader__XSmall]: size === 'xSmall',
        [styles.Loader__Small]: size === 'small',
        [styles.Loader__Medium]: size === 'medium',
        [styles.Loader__Large]: size === 'large',
        [styles.Loader__XLarge]: size === 'xLarge',
        [styles.Loader__White]: theme === 'white',
        [styles.Loader__Blue]: theme === 'blue'
    });

    return (
        <div
            className={classNames(styles.SpinnerContainer, className, {
                [styles.SpinnerContainer__FullHeight]: fullHeight
            })}
            data-testid="loader"
        >
            <div className={loaderClass} />
        </div>
    );
};
