import { createApi } from '@reduxjs/toolkit/query/react';

import {
    CountryState,
    REQUESTS_PATH
} from '@apis/countries/CountriesApi.types';
import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import { RequestStatusWithData } from '@customTypes/api/api.types';
import { Country } from '@customTypes/general/general.types';

export const countriesApi = createApi({
    reducerPath: 'countriesApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getCountries: builder.query<RequestStatusWithData<Country[]>, void>({
            query: () => ({ url: REQUESTS_PATH.COUNTRIES })
        }),
        getStates: builder.query<RequestStatusWithData<CountryState[]>, number>(
            {
                query: (countryId) => {
                    return {
                        url: REQUESTS_PATH.GET_STATES,
                        params: { countryId }
                    };
                }
            }
        )
    })
});

export const { useGetCountriesQuery, useGetStatesQuery } = countriesApi;
