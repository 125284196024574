import React from 'react';

import { Button } from '@atoms/button/Button';
import styles from '@organisms/auth/authConfirmModal/AuthConfirmModal.module.less';

interface Props {
    onButtonClick: VoidFunction;
    title?: string;
    description?: string;
    actionText?: string;
}

const expiredModalDescription =
    'Verification code has expired. Please click the following button to send a new code.';

export const AuthConfirmModal = ({
    onButtonClick,
    description = expiredModalDescription,
    actionText = 'Resend Code',
    title = 'Code expired'
}: Props) => {
    return (
        <div className={styles.ConfirmModal} data-testid="AuthConfirmModal">
            <div className={styles.ConfirmModal__Message}>
                <span
                    className={styles.ConfirmModal__Message__Title}
                    data-testid="AuthConfirmModalTitle"
                >
                    {title}
                </span>
                <span
                    className={styles.ConfirmModal__Message__Desc}
                    data-testid="AuthConfirmModalDescription"
                >
                    {description}
                </span>
            </div>
            <Button
                text={actionText}
                theme="primary"
                testId="AuthConfirmModalSubmit"
                className={styles.ConfirmModal__Submit}
                onClick={onButtonClick}
            />
        </div>
    );
};
