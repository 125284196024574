import { Rule } from 'antd/lib/form';

import { errorPrefix } from '@constants/errorMessages';
import {
    getRequiredRule,
    getRequiredRuleMessage,
    inputAnyCharacterAndSymbolRule,
    inputCityRule,
    inputEmailRule,
    inputPhoneRule,
    inputPostalCodeRule,
    inputSerialNumberRule
} from '@helpers/validationHelpers';

export const getDefaultPostalCodeValidationRules = (
    backEndValidationRegExPattern: string | null,
    value?: string
): Rule[] => {
    return [
        {
            ...getRequiredRule('Postal Code'),
            required: value !== undefined
        },
        inputPostalCodeRule(backEndValidationRegExPattern)
    ];
};

export const getSpecificValidationRule = (fieldName: string): Rule => {
    switch (fieldName) {
        case 'companyName':
        case 'addressLine1':
        case 'addressLine2':
        case 'contactPerson':
        case 'firstName':
        case 'profileName':
        case 'lastName':
        case 'deliveryCity':
            return inputAnyCharacterAndSymbolRule(
                getRequiredRuleMessage(errorPrefix[fieldName], 'Invalid')
            );
        case 'email':
            return inputEmailRule;
        case 'city':
            return inputCityRule;
        case 'phone':
            return inputPhoneRule;
        case 'serial':
            return inputSerialNumberRule(
                getRequiredRuleMessage(errorPrefix[fieldName], 'Invalid')
            );
        default:
            return {};
    }
};

export const getDefaultValidationRules = (
    fieldName: string,
    isRequired = true
): Rule[] => {
    return [
        {
            validator(_: Rule, value: string) {
                if (isRequired && value !== undefined && value === '') {
                    return Promise.reject(
                        getRequiredRuleMessage(errorPrefix[fieldName])
                    );
                }
                return Promise.resolve();
            }
        },
        getSpecificValidationRule(fieldName)
    ];
};
