import React, { Suspense, useEffect, useState, lazy } from 'react';

import { Button } from 'antd';
import { Outlet } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '@atoms/loader/Loader';
import { RoutesList } from '@constants/routesList';
import { useModal } from '@hooks/useModal';
import { useGoBack } from '@hooks/useNavigationHelpers';
import { SvgArrowFullRight } from '@icons/ArrowFullRight';
import { SvgCompassFooterLogo } from '@icons/CompassFooterLogo';
import { SvgCompassLogo } from '@icons/CompassLogo';
import { useAppSelector } from '@store/index';

import styles from './AuthTemplate.module.less';

const PrivacyPolicyModal = lazy(
    () =>
        import(
            'components/UI/organisms/auth/privacyPolicyModal/PrivacyPolicyModal'
        )
);
const TermsConditionsModal = lazy(
    () =>
        import(
            'components/UI/organisms/auth/termsConditionsModal/TermsConditionsModal'
        )
);

export const AuthTemplate = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showBackButton, setShowBackButton] = useState(true);
    const userId = useAppSelector(({ user: { userId } }) => userId);
    const { isOpen: isPrivacyModalOpen, toggle: togglePrivacyModal } =
        useModal();
    const { isOpen: isTermsModalOpen, toggle: toggleTermsModal } = useModal();

    const isSignInPage = location.pathname === '/auth';

    useEffect(() => {
        if (userId) {
            navigate(RoutesList.ROOT);
        }
    }, [userId]);

    const goBack = useGoBack();

    return (
        <div className={styles.Container}>
            <div className={styles.Container__Wrapper}>
                <div className={styles.Container__Content}>
                    {!isSignInPage && showBackButton && (
                        <span className={styles.Back}>
                            <span
                                onClick={goBack}
                                className={styles.Back__Content}
                                data-testid="goBackButton"
                            >
                                <span className={styles.Back__Content__Icon}>
                                    <SvgArrowFullRight color="#273C83" />
                                </span>
                                <span className={styles.Back__Content__Text}>
                                    Back
                                </span>
                            </span>
                        </span>
                    )}
                    <div className={styles.Container__Form}>
                        <div className={styles.Content__Logo}>
                            <SvgCompassLogo color="#273C83" />
                        </div>
                        <div className={styles.Content__Form}>
                            <Outlet
                                context={{
                                    toggleBackButton: setShowBackButton
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.Logo}>
                <div className={styles.Logo__Title}>
                    <a
                        href="https://www.choicelogistics.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <SvgCompassFooterLogo color="#B0BDE8" />
                    </a>
                </div>
            </div>
            <div className={styles.Container__Footer}>
                <Button
                    type="link"
                    className={styles.LinkBtn}
                    onClick={toggleTermsModal}
                    size="small"
                >
                    Terms of Use
                </Button>
                <span>|</span>
                <Button
                    type="link"
                    className={styles.LinkBtn}
                    onClick={togglePrivacyModal}
                    size="small"
                >
                    Privacy Policy
                </Button>
            </div>
            <Suspense
                fallback={
                    <div className={styles.LoaderContainer}>
                        <Loader fullHeight />
                    </div>
                }
            >
                {isTermsModalOpen && (
                    <TermsConditionsModal toggle={toggleTermsModal} />
                )}
                {isPrivacyModalOpen && (
                    <PrivacyPolicyModal toggle={togglePrivacyModal} />
                )}
            </Suspense>
        </div>
    );
};
