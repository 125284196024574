import {
    BULK_TEMPLATES_NAMES,
    DownloadTemplateDTO
} from '@customTypes/bulkOrder/bulk.types';
import { FILE_EXTENSIONS } from '@customTypes/general/general.types';
import { downloadBlob } from '@helpers/downloadBlob';

export const bulkResponseHandler = async (
    response: Response,
    dto: DownloadTemplateDTO,
    fileName: BULK_TEMPLATES_NAMES
): Promise<void> => {
    const fullName =
        dto.fileExtension === FILE_EXTENSIONS.CSV
            ? `${fileName}.csv`
            : `${fileName}.xlsx`;

    return downloadBlob(response, fullName);
};
