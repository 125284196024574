import React from 'react';

import { FormInstance } from 'antd';

import { CountryState } from 'APIServices/countries/CountriesApi.types';
import { FormTextInput } from 'components/UI/molecules/form/formTextInput/FormTextInput';
import { ProvinceSelect } from 'components/UI/molecules/provinceSelect/ProvinceSelect';
import { MAX_LENGTHS } from 'utils/constants/formValidation';
import { DefaultValidationRules } from 'utils/helpers/defaultValidationRules';
import { textInputMaxLength } from 'utils/helpers/validationHelpers';
import { DELIVERY_FORM_FIELDS } from 'utils/types/deliveryServices/deliveryServices.types';
import { ValidationTriggerEvents } from 'utils/types/form/form.types';
import { VoidFunction, Country } from 'utils/types/general/general.types';
import { StepperFormValues } from 'utils/types/general/stepper.types';

import styles from './SelectPartsAndDestination.module.less';

const { postalCodeRequired, textInputRequired } = DefaultValidationRules;

interface PostalCodeCityStatesType {
    form: FormInstance<StepperFormValues>;
    selectedCountry?: Country;
    country?: Country;
    onChangeDestination: VoidFunction;
    hasStates: boolean;
    states?: CountryState[];
    onChangeSelectState?: (value: string) => void;
}

export const PostalCodeCityStates = ({
    form,
    selectedCountry,
    country,
    onChangeDestination,
    hasStates,
    states,
    onChangeSelectState
}: PostalCodeCityStatesType) => {
    return selectedCountry?.hasPostalCodes ? (
        <div className={styles.Delivery__Postal}>
            <FormTextInput
                placeholder="Postal Code"
                fieldName={DELIVERY_FORM_FIELDS.POSTAL_CODE}
                title="Postal Code"
                rules={
                    country &&
                    postalCodeRequired(
                        country.postalCodeRegEx,
                        form.getFieldValue(DELIVERY_FORM_FIELDS.POSTAL_CODE)
                    )
                }
                onChange={onChangeDestination}
                normalize={(value) =>
                    textInputMaxLength(value, MAX_LENGTHS.postalCode)
                }
                validateTrigger={[ValidationTriggerEvents.ON_CHANGE]}
            />
        </div>
    ) : (
        <div className={styles.Row}>
            <div className={styles.Delivery__City}>
                <FormTextInput
                    fieldName={DELIVERY_FORM_FIELDS.CITY}
                    title="City"
                    placeholder="City"
                    rules={textInputRequired(DELIVERY_FORM_FIELDS.CITY)}
                    onChange={onChangeDestination}
                    normalize={(value) =>
                        textInputMaxLength(value, MAX_LENGTHS.city)
                    }
                    validateTrigger={[ValidationTriggerEvents.ON_CHANGE]}
                />
            </div>
            {hasStates && (
                <ProvinceSelect
                    states={states}
                    fieldName={DELIVERY_FORM_FIELDS.STATE}
                    onChangeSelectState={onChangeSelectState}
                    isRequired={true}
                />
            )}
        </div>
    );
};
