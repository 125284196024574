import React from 'react';

import { Divider, Layout } from 'antd';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useRedirectChecker } from '@redirect/useRedirectChecker';
import { SvgChoiceLogo, SvgCompassLogo } from 'components/icons';
import { SiderMenu } from 'components/UI/molecules/siderMenu/SiderMenu';
import { ConfirmRedirectModal } from 'components/UI/organisms/confirmRedirectModal/ConfirmRedirectModal';
import { UserInfoWidget } from 'components/UI/organisms/header/userInfoWidget/UserInfoWidget';
import { isLargestDesktop } from 'utils/constants/breakpoints';
import { RoutesList } from 'utils/constants/routesList';
import { useConfirmRedirect } from 'utils/hooks/useConfirmRedirect';
import { useUser } from 'utils/hooks/useUser';
import { useWindowDimentions } from 'utils/hooks/useWindowDimentions';
import { VoidFunction } from 'utils/types/general/general.types';

import styles from './Sidebar.module.less';

const { Sider } = Layout;

interface Props {
    isSiderOpen?: boolean;
    toggleSiderOnMobile?: (isOpen: boolean) => void;
    signOut: VoidFunction;
}

export const Sidebar = ({
    isSiderOpen,
    toggleSiderOnMobile,
    signOut
}: Props) => {
    const { userInfo } = useUser();

    const { isModalOpen, modalToggle, setRedirectPath, onOkRedirect } =
        useConfirmRedirect(signOut);

    const navigate = useNavigate();
    const blockRedirectCondition = useRedirectChecker();

    const { width } = useWindowDimentions();

    const onMainLogoClick = (path: string) => {
        setRedirectPath?.(path);

        blockRedirectCondition ? modalToggle?.() : navigate(path);
    };

    return (
        <Sider
            collapsible
            collapsedWidth={118}
            trigger={null}
            width={isLargestDesktop(width) ? 270 : 250}
            className={classNames(styles.Sider, {
                [styles.MobileOpen]: isSiderOpen
            })}
        >
            <div className={styles.Container}>
                <div
                    data-testid="sidebar-top-logo"
                    className={styles.Container__Header}
                >
                    <div
                        className={styles.Header__Logo}
                        onClick={() => onMainLogoClick(RoutesList.HOME.ROOT)}
                    >
                        <SvgCompassLogo />
                    </div>
                </div>
                {isSiderOpen && (
                    <UserInfoWidget
                        signOut={signOut}
                        className={styles.Sidebar__UserInfo}
                        userInfo={userInfo}
                        dropdownClassName={styles.UserInfo__Dropdown}
                        onMenuItemClick={toggleSiderOnMobile}
                        modalToggle={modalToggle}
                        setRedirectPath={setRedirectPath}
                    />
                )}
                <Divider />
                <div
                    data-testid="sidebar-menu"
                    className={styles.Container__Content}
                >
                    <SiderMenu
                        setRedirectPath={setRedirectPath}
                        modalToggle={modalToggle}
                        onMenuItemClick={toggleSiderOnMobile}
                    />
                    <div
                        data-testid="sidebar-bottom-logo"
                        className={styles.Container__Footer}
                    >
                        <div className={styles.Footer__Logo}>
                            <a
                                href="https://www.choicelogistics.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <SvgChoiceLogo color="#D8DEF3" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmRedirectModal
                visible={isModalOpen}
                onOk={onOkRedirect}
                onCancelButtonClick={modalToggle}
                onCancel={modalToggle}
            />
        </Sider>
    );
};
