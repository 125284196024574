import { RequestStatusWithData } from '@customTypes/api/api.types';

interface GetKeysData {
    googleAnalyticsKey: string;
    googleMapsKey: string;
}

export type GetKeysResponse = RequestStatusWithData<GetKeysData>;

export enum REQUESTS_PATH {
    GET_KEYS = '/Google/Keys'
}
