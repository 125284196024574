import { createApi } from '@reduxjs/toolkit/dist/query/react';

import {
    GetDashboardsResponse,
    GetLogOnTokenResponse,
    REQUESTS_PATH
} from '@apis/dundas/Dundas.types';
import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';

export const dundasApi = createApi({
    reducerPath: 'dundasApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getLogOnToken: builder.query<GetLogOnTokenResponse, void>({
            query: () => ({
                url: REQUESTS_PATH.GET_LOGON_TOKEN,
                cache: 'no-cache'
            })
        }),
        getDashboards: builder.query<GetDashboardsResponse, void>({
            query: () => ({
                url: REQUESTS_PATH.GET_DASHBOARDS
            })
        })
    })
});

export const { useLazyGetLogOnTokenQuery, useGetDashboardsQuery } = dundasApi;
