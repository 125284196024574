import { TablePaginationConfig } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FilterValue } from 'antd/es/table/interface';

import { GetUserByRoleResponse } from '@apis/users/UsersApi.types';
import {
    Pagination,
    PerPageOptions,
    SorterResult,
    Sorting
} from '@customTypes/general/general.types';
import { FiltersDataType } from '@helpers/filtering';

export interface User {
    firstName: string;
    lastName: string;
    email: string;
}
export enum USER_STATUS_CODES {
    ACTIVATE = 1,
    DEACTIVATED = 2,
    DELETED = 3
}

export interface AdminMain extends User {
    clientId?: number;
}

export interface UserForAdminMain extends User {
    profileId: number;
}

export interface BasicUser extends UserForAdminMain {
    clientId?: number;
}

export type DeactivateUserModalData = {
    id?: string;
    displayName?: string;
    status?: boolean;
};

export interface AdminUser {
    clientId: number;
    clientName: string;
    displayName: string;
    givenName: string;
    id: string;
    lastLogin: string;
    lastModified: string;
    lastModifiedBy: string;
    mail: string;
    profileId: number;
    profileName: string;
    status: boolean;
    surName: string;
}

export type DeleteUserModalData = { fullName: string; id: string };

export const baseUserValidationFields = ['email', 'firstName', 'lastName'];

export interface UseUsers<T> {
    data?: GetUserByRoleResponse<T>['data'];
    isLoading: boolean;
    pagination: Pagination;
    sorting: Sorting;
    showTable?: boolean;
    isNoResults?: boolean;
    perPageOptions?: PerPageOptions;
    onPageChange: (page: number) => void;
    selectFilterBy?: (id: string) => void;
    onFilterChange: (value: string) => void;
    onFilterClear: VoidFunction;
    onFilterInputClear: VoidFunction;
    filterBy?: string;
    filtersData: FiltersDataType[];
    filter: string;
    onTableChange: (
        newPagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<T>
    ) => void;
    onChangeInactive: (event: CheckboxChangeEvent) => void;
    showInactive: boolean;
    showInactiveDisabled: boolean;
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    isChoiceAdminRole: boolean;
    goToEdit: (record: T) => VoidFunction;
    toggleDelete: VoidFunction;
    confirmDelete: VoidFunction;
    deletedUserName?: DeleteUserModalData['fullName'];
    isDeleteOpen: boolean;
    openDeleteModal: (record: T) => VoidFunction;
    onToggleDeactivatedUser: (record: T) => VoidFunction;
    isDeactivateModalOpen: boolean;
    deactivateModalToggle: VoidFunction;
    modalDataForDeactivate: DeactivateUserModalData | null;
    onConfirmedDeactivateUser: VoidFunction;
}
