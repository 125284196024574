import React from 'react';

import { InputType, TextInput } from '@atoms/input/Input';
import { FormField } from '@customTypes/form/form.types';
import { BaseFormBlock } from '@molecules/form/BaseFormBlock';

type Props = FormField &
    Omit<InputType, 'name'> & { isHiddenContainer?: boolean };

export const FormTextInput = ({
    title,
    fieldName,
    rules,
    value,
    initialValue,
    required,
    validateTrigger,
    isHiddenContainer,
    wrapperClassName,
    ...props
}: Props) => {
    return (
        <BaseFormBlock
            title={title}
            fieldName={fieldName}
            rules={rules}
            required={required}
            initialValue={initialValue}
            validateTrigger={validateTrigger}
            isHidden={isHiddenContainer}
            wrapperClassName={wrapperClassName}
        >
            <TextInput value={value} required={required} {...props} />
        </BaseFormBlock>
    );
};
