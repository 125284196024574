import React from 'react';

import { pageHeadersList } from '@constants/pageTitles';
import { usePageTitle } from '@helpers/usePageTitle';
import { NotAllowedTemplate } from '@templates/notAllowed/NotAllowed.template';
import { NotAllowedOnMobileTemplate } from '@templates/notAllowedOnMobile/NotAllowedOnMobile.template';

interface Props {
    isOnMobile?: boolean;
}

export const NotAllowedPage = ({ isOnMobile = false }: Props) => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.NOT_ALLOWED);

    return isOnMobile ? <NotAllowedOnMobileTemplate /> : <NotAllowedTemplate />;
};
