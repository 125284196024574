import axios, { AxiosError } from 'axios';

import {
    QueryWithIntercept,
    queryWithIntercept
} from '@apis/queryWithIntercept';
import { AppController } from '@configs/AppController';
import { setCurrentFlowId } from '@store/slices/user/user';

export const axiosBaseQuery =
    (token: string | null): QueryWithIntercept =>
    async ({ url, method, body: data, params }, api, extraOptions) => {
        try {
            const appConfig = AppController.getInstance().config;
            const baseUrl = `${appConfig?.api_host}/api`;

            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                params,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Api-Version': '3.0'
                },
                ...api,
                ...extraOptions
            });

            const flowId = result.headers['x-correlation-id-flow'];
            const dispatch = api.dispatch;

            if (extraOptions?.flowStart && flowId) {
                dispatch(setCurrentFlowId(flowId));
            }

            if (extraOptions?.flowEnd) {
                dispatch(setCurrentFlowId(''));
            }

            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message
                }
            };
        }
    };

export const axiosQueryWithIntercept: QueryWithIntercept =
    queryWithIntercept(axiosBaseQuery);
