import { ListItem } from '@customTypes/general/general.types';

export interface FiltersDataType extends ListItem {
    id: number;
}

export const filterString = (input: string, filteredValue: string) => {
    return filteredValue?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
};

export const getFormattedFilterBy = (filterBy?: string) =>
    filterBy && filterBy.includes('by')
        ? filterBy.split('by')[1].trim()
        : filterBy;
