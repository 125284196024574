import { RequestStatusWithData } from '@customTypes/api/api.types';
import { ClosestWarehouse } from '@customTypes/general/warehouse.type';

export enum REQUEST_PATH {
    GET_CLOSEST_WAREHOUSES = '/ManageInventory/LocateClosestSsl/GetClosestSsls'
}

export interface GetClosestWarehouseslDTO {
    filters: string;
    sorts: string;
    page: number;
    pageSize: number;
    cacheId?: string;
}

interface GetClosestWarehouseResponseData {
    items: ClosestWarehouse[];
    pagesCount: number;
    totalItemsCount: number;
}

export type GetClosestWarehousesResponse =
    RequestStatusWithData<GetClosestWarehouseResponseData>;
export interface GetTransformedWarehousesResponse
    extends GetClosestWarehousesResponse {
    cacheId?: string;
}
