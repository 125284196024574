import React, { ReactNode } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import { ErrorFallback } from 'components/pages/errorFallback/ErrorFallback.page';
import { RoutesList } from 'utils/constants/routesList';

interface Props {
    children: ReactNode | ReactNode[];
}

export const Boundary = (props: Props) => {
    const navigate = useNavigate();

    const resetScreen = () => {
        navigate(RoutesList.HOME.ROOT, {
            replace: true
        });
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={resetScreen}>
            {props.children}
        </ErrorBoundary>
    );
};
