import * as React from 'react';
import { SVGProps } from 'react';

export const SvgBellLight = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={34}
        height={34}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#bell-states_svg__a)">
            <path
                d="M9.413 22.14a2 2 0 0 0 2.446 2.447M15.586 9.93a6 6 0 1 1 8.485 8.485c-4.95 4.95-4.243 8.485-4.243 8.485L7.101 14.172s3.535.707 8.485-4.243Z"
                stroke="#FFFFFF"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="bell-states_svg__a">
                <path
                    fill="#fff"
                    transform="scale(-1 1) rotate(-45 3.535 49.506)"
                    d="M0 0h24v24H0z"
                />
            </clipPath>
        </defs>
    </svg>
);
