import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import {
    GetAvailableWarehouseDTO,
    REQUEST_PATH,
    GetWarehouseTransformedResponse,
    GetWarehouseResponse
} from '@apis/warehouse/WarehouseApi.types';
import { GetBasicDtoPagination } from '@customTypes/api/api.types';
import { GetPartTransformedResponse } from '@customTypes/general/part.types';
import { getTransformedDataWithHazard } from '@helpers/apiFormatter';
import { getBasePaginationBody } from '@helpers/getBasePaginationBody';

export const warehouseApi = createApi({
    reducerPath: 'warehouseApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getAvailableWarehouse: builder.mutation<
            GetWarehouseTransformedResponse,
            GetAvailableWarehouseDTO
        >({
            query: (dto) => {
                return {
                    url: REQUEST_PATH.AVAILABLE_WAREHOUSE,
                    method: 'POST',
                    body: dto
                };
            },
            transformResponse: (
                response: GetWarehouseResponse
            ): GetWarehouseTransformedResponse => {
                return {
                    ...response,
                    data: {
                        available: response.data?.available?.map((item) => ({
                            ...item,
                            id: item.sslId.toString()
                        })),
                        unavailable: response.data?.unavailable?.map(
                            (item) => ({
                                ...item,
                                id: item.sslId.toString()
                            })
                        )
                    }
                };
            }
        }),
        getWarehouseParts: builder.mutation<
            GetPartTransformedResponse,
            GetBasicDtoPagination
        >({
            query: (dto) => {
                return {
                    url: REQUEST_PATH.INVENTORY,
                    method: 'POST',
                    body: getBasePaginationBody({
                        ...dto,
                        filterFields: 'CustomDescription',
                        fixedSorter: 'partNumber'
                    })
                };
            },
            transformResponse: getTransformedDataWithHazard
        })
    })
});

export const {
    useGetAvailableWarehouseMutation,
    useGetWarehousePartsMutation
} = warehouseApi;
