import React from 'react';

import { TitleField } from '@atoms/titleField/TitleField';
import { PanelBlock } from '@molecules/panelBlock/PanelBlock';

export const HelpCenterTemplate = () => {
    return (
        <PanelBlock
            title="Help Center"
            withDivider={true}
            hideHeaderOnMobile={false}
        >
            <TitleField
                blockTitle="General Questions"
                blockText="Contact your Choice Logistics representative"
                theme="large"
            />
            <TitleField
                blockTitle="Urgent Order Questions"
                blockText="Contact the Order Management team at (800) 861-6900"
                theme="large"
            />
        </PanelBlock>
    );
};
