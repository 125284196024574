import * as React from 'react';
import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
    color?: string;
}

export const SvgSearch = ({ color, ...props }: Props) => (
    <svg
        width={14}
        height={15}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m13 13.5-2.9-2.9m1.567-3.767A5.333 5.333 0 1 1 1 6.833a5.333 5.333 0 0 1 10.667 0Z"
            stroke={color || 'var(--iconStroke)'}
            strokeWidth={1.333}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
