import React from 'react';

import classNames from 'classnames';

import { OrderTicket } from '@apis/trackOrders/TrackOrdersApi.types';
import { Label, LabelVariants } from '@atoms/label/Label';
import { Loader } from '@atoms/loader/Loader';
import { SvgArrowFullRight, SvgWarehouse, SvgTracking } from 'components/icons';

import styles from './Tickets.module.less';

interface Props {
    tickets?: OrderTicket[];
    isLoading?: boolean;
    searchResult?: string;
}

export const Tickets = ({ tickets, isLoading, searchResult }: Props) => {
    const renderDestination = (
        originWarehouse: string | null,
        originCity: string,
        originCountry: string | null
    ) => {
        const fullOrigin = [originCity, originCountry]
            .filter(Boolean)
            .join(', ')
            .toUpperCase();
        return (
            <>
                {originWarehouse && <SvgWarehouse />}
                <div className={styles.Row}>
                    {originWarehouse || fullOrigin}
                </div>
            </>
        );
    };

    return isLoading ? (
        <div className={styles.Loader}>
            <Loader size="small" />
        </div>
    ) : (
        <div className={styles.Wrapper}>
            {tickets?.map(
                ({
                    proNum,
                    currentTicketFlag,
                    sequence,
                    direction,
                    ticketStatus,
                    originCity,
                    isTrackable,
                    originCountry,
                    originSSL,
                    destinationCity,
                    destinationCountry,
                    destinationSSL,
                    serviceLevel,
                    carrier,
                    awbList
                }) => {
                    const trackingNumberList = awbList?.split(',') || [];
                    const isTrackingNumberMatched = Boolean(
                        searchResult &&
                            trackingNumberList.includes(searchResult)
                    );
                    return (
                        <div
                            className={styles.Container__Outer}
                            key={`${proNum}_${sequence}`}
                        >
                            <div className={styles.Container__Inner}>
                                <div className={styles.Ticket__Top}>
                                    <div className={styles.Pro}>
                                        {currentTicketFlag ? (
                                            <div
                                                className={classNames(
                                                    styles.CurrentTicket,
                                                    {
                                                        [styles.CurrentTicket__Hidden]:
                                                            !currentTicketFlag
                                                    }
                                                )}
                                            ></div>
                                        ) : null}
                                        <div
                                            data-testid="OrderTicketProNumber"
                                            className={classNames(styles.Text, {
                                                [styles.Text__Matched]:
                                                    searchResult === proNum
                                            })}
                                        >
                                            {proNum}
                                        </div>
                                        <div
                                            className={classNames(
                                                styles.Text__Grey,
                                                styles.Text
                                            )}
                                        >
                                            {direction}
                                        </div>
                                    </div>
                                    <div
                                        className={styles.Status}
                                        data-testid="OrderTicketStatus"
                                    >
                                        <Label
                                            variant={LabelVariants.NEUTRAL}
                                            name={ticketStatus}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={styles.Container__Middle}
                                    data-testid="OrderTicketOrigin"
                                >
                                    {renderDestination(
                                        originSSL,
                                        originCountry,
                                        originCity
                                    )}
                                    <SvgArrowFullRight />

                                    {renderDestination(
                                        destinationSSL,
                                        destinationCountry,
                                        destinationCity
                                    )}
                                </div>
                                <div className={styles.Container__Bottom}>
                                    {isTrackable && <SvgTracking />}
                                    <div
                                        className={styles.Transport}
                                        data-testid="OrderTicketTransportType"
                                    >
                                        {serviceLevel ? (
                                            <div>{serviceLevel}</div>
                                        ) : (
                                            <>
                                                {carrier && (
                                                    <div>{`${carrier}${
                                                        awbList ? ',' : ''
                                                    }`}</div>
                                                )}
                                                {awbList && (
                                                    <div
                                                        className={classNames(
                                                            styles.Row,
                                                            {
                                                                [styles.Text__Matched]:
                                                                    isTrackingNumberMatched
                                                            }
                                                        )}
                                                    >
                                                        <div>
                                                            {
                                                                trackingNumberList[0]
                                                            }
                                                        </div>

                                                        {trackingNumberList.length >
                                                            1 && (
                                                            <div
                                                                className={
                                                                    styles.Text__Bold
                                                                }
                                                            >
                                                                +
                                                                {trackingNumberList.length -
                                                                    1}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            )}
        </div>
    );
};
