export const REQUESTS_PATH = {
    DOWNLOAD_TEMPLATE: '/CustomerDelivery/GoodPartsUpload/DownloadTemplateFile',
    RESET_FILE: '/CustomerDelivery/GoodPartsUpload/ResetFile/:id',
    GET_COLUMNS_MAPPING: '/CustomerDelivery/GoodPartsUpload/GetColumnsMapping',
    VALIDATE_FILE: '/CustomerDelivery/GoodPartsUpload/ValidateFile',
    GET_AVAILABLE_SERVICES:
        '/CustomerDelivery/GoodPartsUpload/AvailableServices',
    GET_GROUPING: '/CustomerDelivery/GoodPartsUpload/ReviewOrders',
    SUBMIT_ORDERS: '/CustomerDelivery/GoodPartsUpload/SubmitOrders'
} as const;

export const REQUESTS_PATH_DEFECTIVE = {
    DOWNLOAD_TEMPLATE:
        '/ReturnParts/DefectiveFromChoiceUpload/DownloadTemplateFile',
    RESET_FILE: '/ReturnParts/DefectiveFromChoiceUpload/ResetFile/:id',
    GET_COLUMNS_MAPPING:
        '/ReturnParts/DefectiveFromChoiceUpload/GetColumnsMapping',
    VALIDATE_FILE: '/ReturnParts/DefectiveFromChoiceUpload/ValidateFile',
    GET_AVAILABLE_SERVICES:
        '/ReturnParts/DefectiveFromChoiceUpload/AvailableServices',
    GET_GROUPING: '/ReturnParts/DefectiveFromChoiceUpload/ReviewOrders',
    SUBMIT_ORDERS: '/ReturnParts/DefectiveFromChoiceUpload/SubmitOrders'
} as const;
