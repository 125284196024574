import { createSlice } from '@reduxjs/toolkit';

import { Action } from 'store/index';

export interface FilesState {
    uploadProgress: number;
    uploadFileId: string;
}

const initialState: FilesState = {
    uploadProgress: 0,
    uploadFileId: ''
};

export const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setUploadProgress(state, action: Action<number>) {
            state.uploadProgress = action.payload;
        },
        setUploadFileId(state, action: Action<string>) {
            state.uploadFileId = action.payload;
        },
        resetFilesState() {
            return initialState;
        }
    }
});

export const { setUploadProgress, setUploadFileId, resetFilesState } =
    filesSlice.actions;
