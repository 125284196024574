import React from 'react';

import cn from 'classnames';

import { SvgSearch } from 'components/icons';

import styles from './SelectPrefix.module.less';

export interface SelectPrefixProps {
    icon?: React.ReactNode;
    className?: string;
    disabled?: boolean;
}

export const SelectPrefix = ({
    icon,
    className,
    disabled = false
}: SelectPrefixProps) => (
    <div
        className={cn(styles.SelectPrefix, className)}
        data-testid="select_prefix"
    >
        {icon || (
            <SvgSearch
                color={disabled ? '#8E94A9' : ''}
                data-testid="search icon"
            />
        )}
    </div>
);
