import React from 'react';

import { Form } from 'antd';
import classNames from 'classnames';

import { Button } from '@atoms/button/Button';
import { ValidationTriggerEvents } from '@customTypes/form/form.types';
import { textInputMaxLength } from '@helpers/validationHelpers';
import { FormTextInput } from '@molecules/form/formTextInput/FormTextInput';
import styles from '@organisms/auth/AuthForm.module.less';
import { useSignIn } from '@organisms/auth/signIn/useSignIn';

export const SignIn = () => {
    const {
        form,
        isLoading,
        emailRules,
        isAuthError,
        haveAllFields,
        passwordValidation,
        onFinish,
        onChange,
        onForgotClick
    } = useSignIn();

    return (
        <div className={styles.FormWrapper}>
            {isAuthError && (
                <div className={styles.Message__InvalidCredentials}>
                    <span>The email or password doesn't match any account</span>
                </div>
            )}
            <Form
                name="login_form"
                className={styles.Form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                form={form}
                data-testid="login_form"
            >
                <div
                    className={classNames(
                        styles.Form__Fields,
                        styles.Form__Fields__WithoutMaxGap
                    )}
                >
                    <FormTextInput
                        title="Email"
                        fieldName="email"
                        placeholder="Enter your email"
                        rules={emailRules}
                        normalize={(value) => textInputMaxLength(value, 100)}
                        validateTrigger={[ValidationTriggerEvents.ON_BLUR]}
                        data-testid="email_field"
                        onChange={onChange('email')}
                    />
                    <FormTextInput
                        title="Password"
                        fieldName="password"
                        type="password"
                        placeholder="Enter your password"
                        validateTrigger={[ValidationTriggerEvents.ON_BLUR]}
                        rules={passwordValidation}
                        data-testid="pass_field"
                        onChange={onChange('password')}
                    />
                    <span
                        className={styles.Form__Forgot}
                        onClick={onForgotClick}
                    >
                        Forgot your password?
                    </span>
                </div>
                <Button
                    text="Sign In"
                    theme="primary"
                    testId="submit_field"
                    className={styles.Form__Submit}
                    htmlType="submit"
                    disabled={!haveAllFields}
                    isLoading={isLoading}
                />
            </Form>
        </div>
    );
};
