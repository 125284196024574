import React, { ReactNode } from 'react';

import { ModalProps } from 'antd';
import cn from 'classnames';

import { Button } from '@atoms/button/Button';
import { Modal } from '@atoms/modal/Modal';
import { VoidFunction } from 'utils/types/general/general.types';

import styles from './ConfirmModal.module.less';

export interface ConfirmModalProps extends ModalProps {
    mainText: string;
    submitText?: string;
    showSubmitBtn?: boolean;
    cancelText?: string;
    idModalType?: string;
    description?: string;
    onCancelButtonClick?: VoidFunction;
    submitTextIcon?: ReactNode;
}

export const ConfirmModal = ({
    mainText,
    submitTextIcon,
    submitText = '',
    showSubmitBtn = true,
    cancelText,
    description,
    idModalType,
    onCancelButtonClick,
    confirmLoading,
    ...props
}: ConfirmModalProps) => {
    return (
        <Modal
            data-testid="ConfirmModal"
            {...props}
            className={styles.Modal__Confirm}
            footer={
                <div
                    className={cn(styles.Container__Actions, {
                        [styles.Container__Actions__WithSubmitBtn]:
                            !!showSubmitBtn
                    })}
                    data-testid="ConfirmModalFooter"
                >
                    <Button
                        text={cancelText || 'Back'}
                        onClick={onCancelButtonClick || props.onCancel}
                        testId="back_btn"
                        className={styles.Button__Back}
                    />
                    {showSubmitBtn && (
                        <Button
                            text={submitText}
                            theme="primary"
                            onClick={props.onOk}
                            isLoading={confirmLoading}
                            testId="ok_btn"
                            startIcon={submitTextIcon}
                            className={styles.Button__Ok}
                        />
                    )}
                </div>
            }
        >
            <div
                className={styles.Container}
                id={`${idModalType}-confirmModal`}
            >
                <div className={styles.Container__Content}>
                    <div
                        className={styles.Content__Main}
                        data-testid="confirmModalMainText"
                    >
                        {mainText}
                    </div>
                    <div
                        className={styles.Content__Description}
                        data-testid="confirmModalDescriptionText"
                    >
                        {description}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
