import React from 'react';

import { pageHeadersList } from '@constants/pageTitles';
import { usePageTitle } from '@helpers/usePageTitle';
import { WithAccountWrapper } from '@hoc/WithAccountWrapper';
import { LocateClosestTemplate } from '@templates/manageInventory/locateClosestSsl/LocateClosestTemplate';

export const LocateClosestWarehousePage = () => {
    usePageTitle(pageHeadersList.MANAGE_INVENTORY.LOCATE_CLOSEST_WAREHOUSE);

    return (
        <WithAccountWrapper>
            <LocateClosestTemplate />
        </WithAccountWrapper>
    );
};
