import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import {
    GetClosestWarehouseslDTO,
    GetClosestWarehousesResponse,
    GetTransformedWarehousesResponse,
    REQUEST_PATH
} from '@apis/manageInventory/GetClosestWarehouses.type';

export const closestWarehousesApi = createApi({
    reducerPath: 'closestWarehousesApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getClosestWarehouses: builder.mutation<
            GetTransformedWarehousesResponse,
            GetClosestWarehouseslDTO
        >({
            query: (dto) => ({
                url: REQUEST_PATH.GET_CLOSEST_WAREHOUSES,
                method: 'POST',
                body: {
                    filters: dto.filters,
                    page: dto.page,
                    pageSize: dto.pageSize,
                    sorts: dto.sorts
                },
                headers: {
                    'cache-id': dto.cacheId
                }
            }),
            transformResponse: (
                apiResponse: GetClosestWarehousesResponse,
                meta: { response: Response }
            ): GetTransformedWarehousesResponse => {
                return {
                    ...apiResponse,
                    cacheId:
                        meta?.response?.headers.get('cache-id') || undefined
                };
            }
        })
    })
});

export const { useGetClosestWarehousesMutation } = closestWarehousesApi;
