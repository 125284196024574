import { useLocation } from 'react-router-dom';

import { RedirectList } from '@redirect/RedirectList';
import { useAppSelector } from '@store/index';

const emptySelector = () => null;
export const useRedirectChecker = () => {
    const location = useLocation();
    const redirectLocation = RedirectList.find((item) => {
        const nextedItemTrigger = item.nestedItems?.find(
            (el) => location.pathname === `/${el}`
        );

        return location.pathname === `/${item.redirectTo}` || nextedItemTrigger;
    });

    return useAppSelector(
        redirectLocation && redirectLocation.getRedirectBlocker
            ? redirectLocation.getRedirectBlocker
            : emptySelector
    );
};
