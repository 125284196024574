import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import {
    getNotification,
    NOTIFICATION_TYPES
} from '@notifications/Notification';

const endpointWithoutNotifications = [
    'getDownloadDocument',
    'deleteDocument',
    'getTicketDocuments',
    'updateTicketDocument',
    'addTicketDocument'
];

const skipMessageName = 'invalid_grant';

export const ErrorLogger: Middleware = () => (next) => (action) => {
    const skipEndpoint = endpointWithoutNotifications.some(
        (item) => item === action.meta?.arg?.endpointName
    );

    const { meta } = action;

    const skipAction = () => {
        return (
            skipEndpoint ||
            meta.baseQueryMeta?.response?.status === 401 ||
            meta.action?.error?.message === skipMessageName ||
            action.payload?.error === skipMessageName
        );
    };

    if (isRejectedWithValue(action) && !skipAction()) {
        getNotification({
            title: action.payload.data?.title ?? 'Something went wrong',
            type: NOTIFICATION_TYPES.ERROR
        });
    }

    return next(action);
};
