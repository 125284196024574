import { useGetPermissionsQuery } from '@apis/users/Users.api';
import { ElementsType } from '@molecules/siderMenu/SiderMenuItem.types';
import {
    getSideBarItems,
    getAllSideBarItems,
    SideBarItemsWithPermissions
} from '@molecules/siderMenu/SiderMenuItems';
import { useAppSelector } from 'store';

interface SiderMenuItemsType {
    sideBarItems: ElementsType[];
    isLoading: boolean;
}

export const useSiderMenuItems = (): SiderMenuItemsType => {
    const userInfo = useAppSelector(({ user: { userInfo } }) => userInfo);
    const isChoiceAdmin = userInfo?.isChoiceAdmin;
    const { data: permissionsData } = useGetPermissionsQuery(undefined, {
        skip: isChoiceAdmin
    });

    const siderMenuItems = SideBarItemsWithPermissions.filter(
        ({ permission, key }) => {
            const existByGroup = permissionsData?.data?.groups.some(
                ({ keys }) => keys?.includes(permission)
            );

            const existByPermissions = permissionsData?.data?.permissions.some(
                ({ keys }) => keys?.includes(permission)
            );

            const isHomePage = key === 'home';

            return existByGroup || existByPermissions || isHomePage;
        }
    );

    const siderMenuItemsByPermissions = siderMenuItems.map((item) => ({
        ...item,
        children: item.children?.filter(({ permission }) =>
            permissionsData?.data?.permissions.some(({ keys }) =>
                keys?.includes(permission)
            )
        )
    }));
    const sideBarItems = isChoiceAdmin
        ? getAllSideBarItems(userInfo)
        : getSideBarItems({
              userInfo,
              withoutFlagsAndPermissions: false,
              itemsWithPermissions: siderMenuItemsByPermissions
          });

    return {
        sideBarItems,
        isLoading: !permissionsData
    };
};
