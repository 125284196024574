import { useEffect, useState } from 'react';

import { FormInstance, TablePaginationConfig } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { FilterValue } from 'antd/lib/table/interface';

import { LocateClosestWarehouseProps } from 'components/UI/organisms/manageInventory/locateClosestWarehouse/LocateClosestWarehouse';
import {
    INVALID_CITY,
    INVALID_POSTAL_CODE
} from 'utils/constants/errorMessages';
import { capitalize } from 'utils/helpers/stringHelper';
import {
    isPostalCodeOrCityProvinceValid,
    setFieldError
} from 'utils/helpers/validationHelpers';
import { useCountryStateData } from 'utils/hooks/useCountryStateData';
import { useDebouncedValidation } from 'utils/hooks/useDebouncedValidation';
import { useUser } from 'utils/hooks/useUser';
import {
    getNotification,
    NOTIFICATION_TYPES
} from 'utils/notification/Notification';
import { STATUS_CODES } from 'utils/types/api/api.types';
import { DELIVERY_FORM_FIELDS } from 'utils/types/deliveryServices/deliveryServices.types';
import {
    Country,
    VoidFunction,
    SorterResult,
    Sorting,
    SORTING_DIRECTION
} from 'utils/types/general/general.types';
import { ClosestWarehouse } from 'utils/types/general/warehouse.type';

const defaultSorting: Sorting = {
    field: 'distance',
    direction: SORTING_DIRECTION.ASC
};

interface Props
    extends Omit<LocateClosestWarehouseProps, 'closestWarehouseData'> {
    form: FormInstance;
    reset: VoidFunction;
    cacheId?: string;
}

export const useLocateClosestWarehouse = ({
    form,
    getData,
    reset,
    cacheId
}: Props) => {
    const postalCodeField = DELIVERY_FORM_FIELDS.POSTAL_CODE;
    const cityField = DELIVERY_FORM_FIELDS.CITY;
    const stateField = DELIVERY_FORM_FIELDS.STATE;

    const postalCodeValidation = useDebouncedValidation({
        formInstance: form,
        fieldName: postalCodeField
    });

    const [country, setCountry] = useState<Country>();
    const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [sorting, setSorting] = useState<Sorting>(defaultSorting);
    const [includeInactiveWarehouse, setIncludeInactiveWarehouse] =
        useState(true);
    const [isPostalCodeCityStatesVisible, setIsPostalCodeCityStatesVisible] =
        useState(false);

    const { accountNumber, subAccountNumber } = useUser();
    const { countries, states, hasStates } = useCountryStateData(country);

    useEffect(() => {
        form.resetFields();
        setIsPostalCodeCityStatesVisible(false);
        setIsTableVisible(false);
    }, [accountNumber, subAccountNumber]);

    const getClosestWarehouses = async (
        sorting: Sorting,
        withCacheId: boolean
    ) => {
        const destinationProvinceData = states?.find(
            (state) => state.displayName === form.getFieldValue(stateField)
        );

        const city = form.getFieldValue(cityField);
        const code = form.getFieldValue(postalCodeField);
        const inactiveWarehouse = form.getFieldValue('inactiveWarehouse');

        const destinationCountry = country?.countryCode
            ? `,DestinationCountry==${country?.countryCode}`
            : '';

        const destinationProvince = destinationProvinceData?.provinceCode
            ? `,DestinationProvince==${destinationProvinceData?.provinceCode}`
            : '';

        const destinationCity = city ? `,DestinationCity==${city}` : '';
        const destinationPostalCode = code
            ? `,DestinationPostalCode==${code.toUpperCase()}`
            : '';

        const includeInactiveWarehouse = `,IncludeInactiveSsl==${Boolean(
            inactiveWarehouse
        )}`;

        const account = `AccountNum==${accountNumber}`;
        const subAccount = `SubAccountNum==${subAccountNumber}`;

        const sortingField = capitalize(sorting.field);
        const sorts =
            sorting.direction === SORTING_DIRECTION.ASC
                ? `${sortingField},Code`
                : `-${sortingField},Code`;

        const accountsFilter = `${account},${subAccount}`;
        const countryFilters = `${destinationCountry}${destinationProvince}${destinationCity}${destinationPostalCode}`;
        const customFilters = `${countryFilters}${includeInactiveWarehouse}`;

        const dto = {
            page: 1,
            pageSize: 10000,
            filters: `${accountsFilter}${customFilters}`,
            sorts: sorts
        };

        const formattedDto = withCacheId
            ? {
                  ...dto,
                  cacheId
              }
            : dto;

        return await getData(formattedDto).unwrap();
    };

    const onChangeCountry = (option?: DefaultOptionType) => {
        form.resetFields([cityField, postalCodeField, stateField]);
        setCountry(option?.data);
        setSubmitButtonDisabled(true);
    };

    const onInputChange = () => {
        const isCountryFieldCompleted = Boolean(
            form.getFieldValue(DELIVERY_FORM_FIELDS.COUNTRY)
        );
        const isPostalCodeValidAfterSubmit = !form.getFieldError([
            DELIVERY_FORM_FIELDS.POSTAL_CODE
        ])[0];

        const isCityValidAfterSubmit = !form.getFieldError([
            DELIVERY_FORM_FIELDS.CITY
        ])[0];

        setIsPostalCodeCityStatesVisible(isCountryFieldCompleted);

        const isCompleted =
            isCountryFieldCompleted &&
            isPostalCodeValidAfterSubmit &&
            isCityValidAfterSubmit &&
            isPostalCodeOrCityProvinceValid(form, country, hasStates);

        setSubmitButtonDisabled(!isCompleted);
    };

    const onGetWarehouseList = async () => {
        setSorting(defaultSorting);
        setSubmitButtonDisabled(true);
        setIsTableVisible(false);

        const response = await getClosestWarehouses(defaultSorting, false);

        postalCodeValidation.cancel();

        const { statusCode, statusMessage } = response;

        if (
            statusCode === STATUS_CODES.DATA_NOT_FOUND ||
            statusCode === STATUS_CODES.FILTERED_DATA_NOT_FOUND
        ) {
            reset();
            setIncludeInactiveWarehouse(
                form.getFieldValue('inactiveWarehouse')
            );
            setIsTableVisible(true);
            return;
        }

        if (statusCode === STATUS_CODES.INVALID_DESTINATION) {
            form.getFieldValue(postalCodeField)
                ? setFieldError(form, postalCodeField, INVALID_POSTAL_CODE)
                : setFieldError(form, cityField, INVALID_CITY);
            return;
        }

        if (statusCode) {
            getNotification({
                title: statusMessage || 'Error',
                type: NOTIFICATION_TYPES.ERROR
            });
            return;
        }

        setIncludeInactiveWarehouse(form.getFieldValue('inactiveWarehouse'));
        setIsTableVisible(true);
    };

    const onTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<ClosestWarehouse>
    ) => {
        if (!Array.isArray(sorter)) {
            const sortingOptions = sorter.column
                ? {
                      field: `${sorter.field}`,
                      direction: sorter.order as SORTING_DIRECTION
                  }
                : defaultSorting;
            setSorting(sortingOptions);
            void getClosestWarehouses(sortingOptions, true);
        }
    };

    return {
        countries,
        country,
        hasStates,
        states,
        isPostalCodeCityStatesVisible,
        isSubmitButtonDisabled,
        isTableVisible,
        sorting,
        includeInactiveWarehouse,
        onChangeCountry,
        onInputChange,
        onGetWarehouseList,
        onTableChange
    };
};
