import React, { useState } from 'react';

import classNames from 'classnames';

import { TransformedTrackOrder } from '@apis/trackOrders/TrackOrdersApi.types';
import { Loader } from '@atoms/loader/Loader';
import { ToolTip } from '@atoms/tooltip/ToolTip';
import { useWatchListItems } from '@organisms/home/watchList/watchListItems/useWatchListItems';
import styles from '@organisms/home/watchList/watchListItemsMobile/WatchListItemsMobile.module.less';
import { renderOriginDestination } from '@organisms/trackOrders/orderList/columns';
import { ReferenceNumbers } from '@organisms/trackOrders/orderList/shared/referenceNumbers/ReferenceNumbers';
import { SvgArrowFullRight, SvgOrderWatchlist } from 'components/icons';

interface Props {
    watchlistItems?: TransformedTrackOrder[];
    isLoading?: boolean;
    isMobileVersion?: boolean;
    isDarkModeEnabled?: boolean;
}

export interface AddedWatchlistItems {
    watchlistItemId: string;
    isInOrderWatchList: boolean;
}

export const WatchListItemsMobile = ({
    watchlistItems,
    isLoading,
    isMobileVersion,
    isDarkModeEnabled
}: Props) => {
    const [addedToWatchListItems, setAddedToWatchListItems] = useState<
        AddedWatchlistItems[]
    >([]);

    const {
        toggleWatchList,
        renderDateInfo,
        goToDetailsPage,
        checkMatchedFlagItems,
        renderWatchListItemStatus
    } = useWatchListItems({
        watchlistItems,
        addedToWatchListItems,
        setAddedToWatchListItems,
        isMobileVersion
    });

    const spawnWatchListIcon = (isAddedToWatchList: boolean) => {
        const classNameForSvg = classNames(styles.WatchListIcon, {
            [styles.WatchListIcon__Selected]: isAddedToWatchList
        });

        if (isAddedToWatchList) {
            return (
                <SvgOrderWatchlist
                    className={classNameForSvg}
                    data-testid="watchList-flag-icon"
                />
            );
        }

        return (
            <SvgOrderWatchlist
                className={classNameForSvg}
                data-testid="watchList-flag-icon"
            />
        );
    };

    const spawnLoaderSection = () => {
        return (
            <div
                className={styles.LoaderContainer}
                data-testid="loader-container"
            >
                <Loader size="medium" data-testid="loader" fullHeight />
            </div>
        );
    };

    const spawnWatchListItem = () => {
        return watchlistItems?.map((item, index) => {
            return (
                <div
                    key={index}
                    className={styles.WatchListItemMobile}
                    data-testid="watchlist-item"
                >
                    <div
                        className={styles.ListItem}
                        data-testid="list-item-section"
                    >
                        <div className={styles.ListItem__Header}>
                            <div className={styles.Header__Status}>
                                {renderWatchListItemStatus(item.status)}
                            </div>
                            <div className={styles.ListItem__Reference}>
                                <ReferenceNumbers
                                    referenceNumbers={item.referenceNumbers}
                                    isMobileViewSetting={true}
                                />
                            </div>
                        </div>

                        <div className={styles.ListItem__OriginDestination}>
                            <div className={styles.ListItem__Title}>
                                Origin:
                            </div>
                            {renderOriginDestination({
                                originDestination: item.origin,
                                watchListMobileMode: true
                            })}
                        </div>
                        <div className={styles.ListItem__OriginDestination}>
                            <div className={styles.ListItem__Title}>
                                Destination:
                            </div>
                            {renderOriginDestination({
                                originDestination: item.destination,
                                watchListMobileMode: true
                            })}
                        </div>
                        <div className={styles.ListItem__Date}>
                            <div className={styles.ListItem__Title}>
                                Pick Up Date:
                            </div>
                            <ToolTip
                                overlayClassName={styles.Tooltip}
                                title={renderDateInfo({
                                    dateActual: item?.pickUpDateActual,
                                    dateEstimated: item.pickUpDateEstimated,
                                    dateTimeZone: item.pickUpDateTimeZone
                                })}
                                placement="bottom"
                                data-testid="pick-up-date-tooltip"
                            >
                                <div>
                                    {renderDateInfo({
                                        dateActual: item?.pickUpDateActual,
                                        dateEstimated: item.pickUpDateEstimated,
                                        dateTimeZone: item.pickUpDateTimeZone,
                                        isTimeZone: false,
                                        watchListMobileMode: true
                                    })}
                                </div>
                            </ToolTip>
                        </div>
                        <div className={styles.ListItem__Date}>
                            <div className={styles.ListItem__Title}>
                                Delivery Date:
                            </div>
                            <ToolTip
                                overlayClassName={styles.Tooltip}
                                title={renderDateInfo?.({
                                    dateActual: item?.deliveryDateActual,
                                    dateEstimated: item.deliveryDateEstimated,
                                    dateTimeZone: item.deliveryDateTimeZone
                                })}
                                placement={
                                    isMobileVersion ? 'bottom' : 'rightBottom'
                                }
                                data-testid="delivery-date-tooltip"
                            >
                                <div>
                                    {renderDateInfo?.({
                                        dateActual: item?.deliveryDateActual,
                                        dateEstimated:
                                            item.deliveryDateEstimated,
                                        dateTimeZone: item.deliveryDateTimeZone,
                                        isTimeZone: false,
                                        watchListMobileMode: true
                                    })}
                                </div>
                            </ToolTip>
                        </div>
                        <div className={styles.ListItem__Footer}>
                            <div
                                onClick={() =>
                                    toggleWatchList(
                                        item.id,
                                        item.isInOrderWatchList
                                    )
                                }
                                className={styles.ListItem__WatchIcon}
                            >
                                {spawnWatchListIcon(
                                    checkMatchedFlagItems({
                                        dataItemId: item.id
                                    })
                                )}
                            </div>
                            <div
                                className={styles.ListItem__DetailsLink}
                                onClick={() => goToDetailsPage(item)}
                            >
                                <div className={styles.ListItem__LinkTitle}>
                                    Details
                                </div>
                                <div className={styles.ArrowContainer}>
                                    <SvgArrowFullRight
                                        color={
                                            isDarkModeEnabled
                                                ? '#b2c0f2'
                                                : '#273C83'
                                        }
                                        data-testid="arrow-right-icon"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={styles.Container}>
            {isLoading ? spawnLoaderSection() : spawnWatchListItem()}

            {!watchlistItems?.length && !isLoading && (
                <div
                    className={styles.NoOrders}
                    data-testid="no-orders-section-mobile"
                >
                    No orders in your watchlist yet
                </div>
            )}
        </div>
    );
};
