import React from 'react';

import { Form } from 'antd';
import classNames from 'classnames';

import { FormField, TriggerEventType } from '@customTypes/form/form.types';

import styles from './BaseFormBlock.module.less';

interface Props extends FormField {
    children: string | React.ReactNode | React.ReactNode[];
    width?: string;
    required?: boolean;
    validateTrigger?: Array<TriggerEventType>;
    isHidden?: boolean;
}

export const BaseFormBlock = ({
    rules,
    fieldName,
    title,
    children,
    required = true,
    initialValue,
    validateTrigger,
    isHidden = false,
    wrapperClassName
}: Props) => {
    return (
        <div
            className={classNames(styles.Container, wrapperClassName, {
                [styles.Container__Invisible]: isHidden
            })}
        >
            {title || !required ? (
                <div className={styles.Container__Labels}>
                    {title && (
                        <div className={styles.Container__Title}>{title}</div>
                    )}
                    {!required ? (
                        <div className={styles.Container__Optional}>
                            Optional
                        </div>
                    ) : null}
                </div>
            ) : null}
            <Form.Item
                name={fieldName}
                rules={rules}
                initialValue={initialValue}
                validateTrigger={validateTrigger}
            >
                {children}
            </Form.Item>
        </div>
    );
};
