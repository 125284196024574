import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import { REQUESTS_PATH } from '@apis/notifications/Notifications.type';
import {
    GetUnreadCountResponse,
    NotificationsRequest,
    NotificationsResponse
} from '@customTypes/notifications/notifications.type';

export const notificationsApi = createApi({
    reducerPath: 'notificationsApi',
    baseQuery: baseQueryWithIntercept,
    tagTypes: ['NOTIFICATIONS_COUNT'],
    endpoints: (builder) => ({
        getNotifications: builder.mutation<
            NotificationsResponse,
            NotificationsRequest
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.GET_NOTIFICATIONS,
                method: 'POST',
                body: dto
            })
        }),
        getUnreadCount: builder.query<GetUnreadCountResponse, void>({
            query: () => ({
                url: REQUESTS_PATH.GET_UNREAD_COUNT,
                method: 'GET'
            }),
            providesTags: ['NOTIFICATIONS_COUNT']
        }),
        readAllNotifications: builder.mutation<NotificationsResponse, void>({
            query: () => ({
                url: REQUESTS_PATH.READ_ALL_NOTIFICATIONS,
                method: 'PUT'
            }),
            invalidatesTags: ['NOTIFICATIONS_COUNT']
        }),
        readSingleNotification: builder.mutation<NotificationsResponse, string>(
            {
                query: (notificationId) => ({
                    url: `${REQUESTS_PATH.READ_SINGLE_NOTIFICATION}/${notificationId}`,
                    method: 'PUT'
                })
            }
        )
    })
});

export const {
    useGetNotificationsMutation,
    useLazyGetUnreadCountQuery,
    useReadAllNotificationsMutation,
    useReadSingleNotificationMutation
} = notificationsApi;
