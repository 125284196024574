import { useEffect, useState } from 'react';

import { Form, FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';
import {
    Location,
    useLocation,
    useNavigate,
    useOutletContext
} from 'react-router-dom';

import AuthApi from '@apis/auth/auth.api';
import { VerificationData } from '@apis/auth/authApi.types';
import { RoutesList } from '@constants/routesList';
import { CreatePasswordForm } from '@customTypes/users/authForm.types';
import {
    getNotification,
    NOTIFICATION_TYPES
} from '@notifications/Notification';
import { useValidation } from '@organisms/auth/passwordRecovery/createPassword/useValidation';

interface LocationState extends Location, VerificationData {
    email: string;
}

interface UseCreatePasswordReturn {
    form: FormInstance<CreatePasswordForm>;
    initialData: LocationState;
    onContinue: (values: CreatePasswordForm) => Promise<void>;
    isLoading: boolean;
    confirmIsDisabled: boolean;
    isTokenExpired: boolean;
    isSuccessModalOpen: boolean;
    onStartNewSession: VoidFunction;
    passwordRules: Rule[];
    repeatPasswordRules: Rule[];
    onConfirm: VoidFunction;
    passwordChangeHandler: (
        field: 'password' | 'confirmPassword'
    ) => (value: string) => void;
}

export const useCreatePassword = (): UseCreatePasswordReturn => {
    const navigation = useNavigate();
    const { toggleBackButton } = useOutletContext<{
        toggleBackButton: (isOpen: boolean) => void;
    }>();

    const { state: locationState } = useLocation();
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const initialData = locationState as LocationState;
    const [isLoading, setIsLoading] = useState(false);
    const [confirmIsDisabled, setConfirmIsDisabled] = useState(true);
    const [form] = Form.useForm<CreatePasswordForm>();

    const password = Form.useWatch('password', form);
    const confirmPassword = Form.useWatch('confirmPassword', form);

    const validatePasswords = () => {
        form.validateFields(['password', 'confirmPassword'])
            .then(() => {
                setConfirmIsDisabled(false);
            })
            .catch(() => {
                setConfirmIsDisabled(true);
            });

        setConfirmIsDisabled(true);
    };

    useEffect(() => {
        if (password && confirmPassword) {
            validatePasswords();
        }
    }, [password, confirmPassword]);

    useEffect(() => {
        if (!initialData?.email) {
            navigation('../../');
        }
    }, [initialData?.email]);

    const toggleExpiredCode = (showBackButton: boolean) => {
        setIsTokenExpired((prevState) => !prevState);
        toggleBackButton(showBackButton);
    };

    const onContinue = async (values: CreatePasswordForm) => {
        setIsLoading(true);
        if (values.confirmPassword === values.password) {
            const { response, isError, isExpired } =
                await AuthApi.changePassword({
                    newPassword: values.password,
                    emailConfirmationId: initialData?.emailConfirmationId,
                    token: initialData?.token
                });

            if (isError && isExpired) {
                toggleExpiredCode(false);
                setIsLoading(false);
                return;
            }

            if (isError) {
                getNotification({
                    title: 'Something went wrong',
                    type: NOTIFICATION_TYPES.ERROR
                });
                setIsLoading(false);
                return;
            }

            if (response) {
                setIsSuccessModalOpen(true);
                toggleBackButton(false);
            }
        }
        setIsLoading(false);
    };

    const onConfirm = () => {
        setIsSuccessModalOpen(false);
        navigation(`../../`, {
            replace: true
        });
    };

    const { passwordRules, repeatPasswordRules } = useValidation(
        form,
        isLoading
    );

    const onStartNewSession = () => {
        navigation(`../${RoutesList.AUTH.VERIFICATION}`);
        setIsTokenExpired(false);
        toggleExpiredCode(false);
    };

    const passwordChangeHandler =
        (field: 'password' | 'confirmPassword') => (value: string) => {
            form.setFieldsValue({ [field]: value });
        };

    return {
        onConfirm,
        onContinue,
        onStartNewSession,
        form,
        isLoading,
        initialData,
        passwordRules,
        isTokenExpired,
        isSuccessModalOpen,
        repeatPasswordRules,
        confirmIsDisabled,
        passwordChangeHandler
    };
};
